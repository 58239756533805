import React, { Component } from 'react';
import logo from '../../assets/logo-2.png';
import Digital from 'react-activity/lib/Digital';
import Spinner from 'react-loading-overlay';
import 'react-activity/lib/Digital/Digital.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LogIn from '../login';
import DashBoard from '../dashboard';
import BottomNav from '../BottomNavScreen';


export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  isLoggedInAlready() {
    const isUSer = localStorage.getItem("userID");
    console.log("userName", isUSer);
    if (isUSer) {
      return true;
    } else {
      return false
    }
  }

  render() {
    return (
      //  <div>

      //      <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
      //     <Digital
      //     animation={true}>

      //     </Digital>

      //  </div>
      // <div style={{display:"flex",position:"absolute",left:0,right:0,top:0,bottom:0}}>
      // <div style={{margin:"auto",display:"flex"}}>


      //     <img src={logo} style={{position:"fixed",left:0,right:0,top:0,bottom:0,margin:"auto"}}/>
      //     <Digital
      //     position="relative"
      //     top ={101}

      //     animation={true}
      //     >

      //     </Digital>
      //   </div>

      // </div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => (
            this.isLoggedInAlready() ? (
              <Redirect to="/home" />
            ) : (
                <LogIn />
              )
          )} />
          <Route path="/home" component={BottomNav} />
          <Route path="/login" component={LogIn} />
          <Route path="/index.html" component={BottomNav}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

