import React from 'react';
import '../BottomNavScreen/index.css'
import { LoadContent, NavDiv, NavHome, MainContainer, NavSearch, NavCustomer, NavSettings, NavBulkOperations, NavLabel, NavTransactions, NavStockOperation, NavUnpaidSTB } from './styled';
import {
    MdHome,
    MdSearch,
    MdSettings,
    MdPermIdentity,
    MdPersonAdd,
    MdTransferWithinAStation,
    MdShoppingBasket
} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
import { IoIosCloseCircle } from "react-icons/io";
import DashBoard from '../dashboard';
import Search from '../CustomerSearch';
import BulkOperations from '../BulkOperations';
import CustomerDetails from '../CustomerDetails';
import CreateCustomerForm from '../CreateCustomer'
import Transactions from '../Transactions';
import API from '../../Api';
import UnmappedSTB from '../UnmappedSTB';
import AddPackPage from '../CustomerAddPackage';
import Credit from '../Transactions/CreditTransactions'
import Debit from '../Transactions/DebitTransactions'
import Notifications from '../Transactions/NotifiactionsTransactions'
import Profile from '../Transactions/Profile';
import ActivityLog from '../Transactions/ActivityLog';
// import StockOperation from '../StockOperation';
import StockOperation from '../InStock';
import STBCreation from '../InStock/STBCreation';
import STBTransfer from '../InStock/STBTransfer';

// import UnpaidSTB from "../UnpaidSTB"

import { FaFastForward } from 'react-icons/fa';

import STBReturns from '../InStock/STBReturns';
import CustomerTransfer from '../InStock/CustomerTransfer';
import FullDataProcess from '../fullDataProcess';
import Report from '../Transactions/Report';
import CreateCustomerWithSTB from '../InStock/CreateCustomerWithSTB';
import BulkCustomerCreation from '../Transactions/BulkCustomerCreation';
import BulkPackAddandRemove from '../InStock/BulkPackAddandRemove';
import Undraw from 'react-undraw';
import img1 from '../../assets/MTGif.gif';

// import STBCreation from '../StockOperation/STBCreation';
export default class index extends React.Component {

    constructor(props) {
        console.log("version 4.7.1");
        super(props);
        this.state = {

            content: "dashboard",
            dashboardNavColor: "#9bc5e0",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            transactionsNavColor: "ffffff",
            settingsNavColor: "#ffffff",
            stockOperationColor: "#ffffff",
            specifyUserDetails: {},
            specifyUnmappedSTB: {},
            customerNo: "",
            unmappedSTBNO: "",
            searchText: "",
            searchQuery: "",
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            pageNo: 1,
            tableData: [],
            isShowStock: localStorage.getItem("isShowStock"),
            isShowAdd: false
        }
        console.log("isShow", localStorage.getItem("isShowStock"), this.state.isShowStock);
    }
    // componentWillUnmount(){
    //     this.setState({searchQuery:""});
    //     console.log("vera level",this.state.searchQuery);
    // }
    showTheContent(content) {
        switch (content) {
            case "dashboard":
                return <DashBoard
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToSearchWithQuery={this.moveToSearchWithQuery.bind(this)}
                    onClickProfile={this.onClickProfile.bind(this)}
                />;
            case "search":
                return <Search
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)}
                    searchQuery={this.state.searchQuery}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />;
            case "bulkoperations":
                return <BulkOperations />;
            // case "UnpaidSTB":
            //     return <UnpaidSTB/>;
            case "customerdetails":
                return <CustomerDetails
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    specifyUserDetails={this.state.specifyUserDetails} moveToBackToCustomerSearch={this.moveToBackToCustomerSearch.bind(this)} />;
            case "unmappedSTB":
                return <UnmappedSTB
                    onMoveToTransactions={this.onMoveToTransactions.bind(this)}
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)} />;
            // case "stockoperation":
            // return <StockOperation 
            // moveToSTBCreation={this.moveToSTBCreation.bind(this)}
            // />;
            case "createCustomerForm":
                return <CreateCustomerForm
                    specifyUnmappedSTB={this.state.specifyUnmappedSTB}
                    moveToBackToUnmappedSTB={this.moveToBackToUnmappedSTB.bind(this)}
                    moveToAddPackPage={this.moveToAddPackPage.bind(this)}
                />
            case "addpackpage":
                return <AddPackPage
                    backToUnMappedSTB={this.backToUnMappedSTB.bind(this)}
                    customerNo={this.state.customerNo}
                    STBNo={this.state.unmappedSTBNO} />
            // case "stockoperation":
            // return <AddPackPage
            //     backToUnMappedSTB={this.backToUnMappedSTB.bind(this)}
            //     customerNo={this.state.customerNo}
            //     STBNo={this.state.unmappedSTBNO} />
            case "transactions":
                return <Transactions
                    onClickCredit={this.onClickCredit.bind(this)}
                    onClickDebit={this.onClickDebit.bind(this)}
                    onClickNotifications={this.onClickNotifications.bind(this)}
                    onClickProfile={this.onClickProfile.bind(this)}
                    onClickActivityLog={this.onClickActivityLog.bind(this)}
                    moveToUnmappedSTB={this.moveToUnmappedSTB.bind(this)}
                    moveToReport={this.moveToReport.bind(this)}
                    moveToBulkCustomerCreation={this.moveToBulkCustomerCreation.bind(this)}
                />
            case "credit":
                return <Credit onMoveToTransactions={this.onMoveToTransactions.bind(this)} tableData={this.state.tableData} />
            case "debit":
                return <Debit onMoveToTransactions={this.onMoveToTransactions.bind(this)} />
            case "notifications":
                return <Notifications onMoveToTransactions={this.onMoveToTransactions.bind(this)} />
            case "profile":
                return <Profile onMoveToTransactions={this.onMoveToTransactions.bind(this)} />

            case "report":
                return <Report onMoveToTransactions={this.onMoveToTransactions.bind(this)} />
            case "activitylog":
                return <ActivityLog onMoveToTransactions={this.onMoveToTransactions.bind(this)} />
            case "bulkcustomercreation":
                return <BulkCustomerCreation
                    // backToStockOperation={this.backToStockOperation.bind(this)}
                    onMoveToTransactions={this.onMoveToTransactions.bind(this)} />
            case "instock":
                return <StockOperation
                    moveToSTBCreation={this.moveToSTBCreation.bind(this)}
                    moveToSTBTransfer={this.moveToSTBTransfer.bind(this)}
                    moveToSTBReturns={this.moveToSTBReturns.bind(this)}
                    moveToCustomerTransfer={this.moveToCustomerTransfer.bind(this)}
                    moveToCreateCustomerWithSTB={this.moveToCreateCustomerWithSTB.bind(this)}
                    moveToBulkPackAddandRemove={this.moveToBulkPackAddandRemove.bind(this)}
                />
            case "stbcreation":
                return <STBCreation
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />
            case "stbtransfer":
                return <STBTransfer
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />

            case "stbreturns":
                return <STBReturns
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />
            case "customertransfer":
                return <CustomerTransfer
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />
            case "createcustomerwithstb":
                return <CreateCustomerWithSTB
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />
            case "fulldataprocess":
                return <FullDataProcess

                />
            case "bulkpackaddandremove":
                return <BulkPackAddandRemove
                    backToStockOperation={this.backToStockOperation.bind(this)}
                />
            default:
                return null;
        }
    }
    backToUnMappedSTB() {

        this.setState({
            content: "unmappedSTB",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#9bc5e0",
            stockOperationColor: "#ffffff"

        });
    }
    moveToSTBCreation() {
        this.setState({ content: "stbcreation" });
    }
    moveToBulkPackAddandRemove() {
        this.setState({ content: "bulkpackaddandremove" });
    }
    // moveToSTBCreation1(){
    //     this.setState({content: "stbcreation"});
    // }
    moveToSTBTransfer() {
        this.setState({ content: "stbtransfer" })
    }
    moveToBulkCustomerCreation() {
        this.setState({ content: "bulkcustomercreation" })
    }

    moveToCreateCustomerWithSTB() {
        this.setState({ content: "createcustomerwithstb" })
    }
    moveToCustomerTransfer() {
        this.setState({ content: "customertransfer" })
    }
    moveToSTBReturns() {
        this.setState({ content: "stbreturns" })
    }
    moveToReport() {
        this.setState({ content: "report" });
    }
    moveToDashBoard() {
        this.setState({
            content: "dashboard",
            dashboardNavColor: "#9bc5e0",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        })
    }

    backToStockOperation() {
        this.setState({ content: "instock" })
    }
    moveToUnmappedSTB() {
        this.setState({ content: "unmappedSTB" })
    }



    moveToSearchWithQuery(text) {
        this.setState({
            content: "search",
            searchQuery: text,
            dashboardNavColor: "#ffffff",
            searchNavColor: "#9bc5e0",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        });
        setTimeout(() => {
            this.setState({ searchQuery: "" });
            console.log("current txt", text);
        }, 1000)
        console.log("current txt", text);
    }

    onMoveToTransactions() {
        this.setState({ content: "transactions" });
    }
    moveToCreateCustomerForm(text) {
        debugger;
        console.log("unmappedstbno", text);
        this.setState({
            content: "createCustomerForm",
            specifyUnmappedSTB: text,
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#9bc5e0",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"

        });
        console.log("data", this.state.specifyUnmappedSTB);
        debugger;
    }

    onClickCredit() {


        this.setState({ content: "credit" });

    }
    onClickDebit() {
        this.setState({ content: "debit" });
    }
    onClickNotifications() {
        this.setState({ content: "notifications" });
    }
    onClickProfile() {
        this.setState({
            content: "profile",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#9bc5e0"

        });
    }
    onClickActivityLog() {
        this.setState({ content: "activitylog" });
    }
    moveToAddPackPage(customerNo, STBNo) {
        this.setState({ content: "addpackpage", customerNo: customerNo, unmappedSTBNO: STBNo });
    }
    async moveToBackToCustomerSearch() {

        this.setState({ content: "search", searchQuery: this.state.searchText });
        console.log("backtosearch : ", this.state.searchQuery)

    }
    async moveToBackToUnmappedSTB() {

        this.setState({ content: "unmappedSTB" });
    }

    moveToCustomerDetails(item, text, searchTxt) {
        console.log("item", text);
        console.log("text", item);
        console.log("working super", searchTxt);
        const data1 = item;

        console.log("data1", text, searchTxt);
        this.state.searchText = searchTxt;
        debugger;
        this.setState({
            content: "customerdetails",
            specifyUserDetails: text,
            searchQuery: searchTxt,
            dashboardNavColor: "#ffffff",
            searchNavColor: "#9bc5e0",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        });
        console.log("data", this.state.specifyUserDetails);
    }
    isShowStockOperation() {

        if (this.state.isShowStock != "false") {

            return (
                <NavStockOperation
                    onClick={() => {
                        this.setState({
                            content: "instock",
                            dashboardNavColor: "#ffffff",
                            searchNavColor: "#ffffff",
                            createCustomerNavColor: "#ffffff",
                            settingsNavColor: "#ffffff",
                            transactionsNavColor: "#ffffff",
                            stockOperationColor: "#9bc5e0"
                        });
                    }
                    }
                    color={this.state.stockOperationColor}
                >
                    <div className="bottom-nav-active">
                        <MdShoppingBasket className="font-size" />
                        <NavLabel>Stock Operation</NavLabel>
                    </div>
                </NavStockOperation>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            // <MainContainer>
            //     <LoadContent></LoadContent>
            //     <NavDiv>

            //         <NavHome>
            //             <MdHome className="font-size" />
            //             <NavLabel>DASHBOARD</NavLabel>
            //         </NavHome>
            //         <NavSearch >
            //             <MdSearch className="font-size" />
            //             <NavLabel>SEARCH</NavLabel>
            //         </NavSearch>
            //         <NavCustomer>
            //             <MdPermIdentity className="font-size" />
            //             <NavLabel>Customer Details</NavLabel>
            //         </NavCustomer>
            //         <NavSettings>
            //             <MdSettings className="font-size" />
            //             <NavLabel>Settings</NavLabel>
            //         </NavSettings>
            //     </NavDiv>
            // </MainContainer>

            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
                <div style={{ flex: 1 }}>

                    <div


                        onScroll={() => console.log()}

                        style={{ width: "100%", height: "90%", overflow: "auto" }}
                    >
                        {this.showTheContent(this.state.content)}
                    </div>
                    <div
                        style={{ width: "100%", height: "10%" }}
                    >
                        <NavDiv>

                            <NavHome

                                onClick={() => {
                                    this.setState({
                                        content: "dashboard",
                                        dashboardNavColor: "#9bc5e0",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.dashboardNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdHome className="font-size" />
                                    <NavLabel>DASHBOARD</NavLabel>
                                </div>
                            </NavHome>
                            <NavSearch
                                onClick={() => {
                                    this.setState({
                                        content: "search",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#9bc5e0",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.searchNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdSearch className="font-size" />
                                    <NavLabel >SEARCH</NavLabel>
                                </div>
                            </NavSearch>
                            <NavCustomer
                                onClick={() => {
                                    this.setState({
                                        content: "fulldataprocess",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#9bc5e0",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.createCustomerNavColor}
                            >
                                <div className="bottom-nav-active">
                                    {/* <MdPersonAdd className="font-size" /> */}
                                    <IoIosCloseCircle className="font-size" />
                                    <NavLabel>Unpaid</NavLabel>
                                </div>
                            </NavCustomer>
                            <NavBulkOperations
                                onClick={() => {
                                    this.setState({
                                        content: "bulkoperations",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#9bc5e0",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.settingsNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdSettings className="font-size" />
                                    <NavLabel>BULK OPERATIONS</NavLabel>
                                </div>
                            </NavBulkOperations>
                            {this.isShowStockOperation()}
                            <NavTransactions
                                onClick={() => {
                                    this.setState({
                                        content: "transactions",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#9bc5e0",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.transactionsNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdTransferWithinAStation className="font-size" />
                                    <NavLabel>Others</NavLabel>
                                </div>
                            </NavTransactions>


                            {/* <NavUnpaidSTB
                                onClick={() => {
                                    this.setState({
                                        content: "UnpaidSTB",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#9bc5e0",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.settingsNavColor}
                            >
                                <div className="bottom-nav-active"> 
                                <MdSettings className="font-size" />
                                <NavLabel>UnpaidSTB</NavLabel>
                                </div>
                            </NavUnpaidSTB> */}

                        </NavDiv>

                    </div>

                </div>
                <Drawer
                    
                    open={this.state.isShowAdd}
                    onRequestClose={this.toggle}
                    direction='bottom'
                >

                    <Card 
                    style={{backgroundColor:"transparent",overflow:"auto",borderColor:"transparent"}}
                    >


                        <Card.Body className="pl-3 ">
                      
                          
                       <div style={{width:"70vw",height:"50vh",textAlign:"center"}}>
                           <img
                           style={{maxHeight:"100%",maxWidth:"100%"}}
                           src="https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg"
                           ></img>

                       </div>

                            <div style={{ textAlign: "center" }}>
                                <Button
                                    style={{ backgroundColor: "#E64A19", borderColor: "#E64A19",  marginTop: 10 }}

                                    onClick={() => {
                                        this.setState({ isShowAdd: false })

                                    }}
                                >Close</Button>
                            </div>



                        </Card.Body>
                    </Card>

                </Drawer>


            </div>

        )
    }
}