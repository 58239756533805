import React from "react";
import { Button, ButtonGroup, Container, Card } from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "./styled";
import "./index.css";
import { Redirect } from "react-router-dom";
import {
  MdPermIdentity,
  MdHome,
  MdFiberPin,
  MdCreditCard,
  MdRefresh,
  MdAddCircle,
  MdRemoveCircle,
  MdPowerSettingsNew,
  MdArrowBack
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    console.log("data from search", this.props.specifyUserDetails);
    const data = this.props.specifyUserDetails;
    console.log("data in customer details", data);

    this.state = {
      userMetaData: data,
      userSTBDetails: {},

      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,

      spinnerVisible: false,
      pageCount: 3,
      totalPage: 0,
      count: "",
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      pageNo: 3,
      tableDataValue: "",
      isVisibleConformADD_Dialog: false,
      isVisibleConformRemove_Dialog: false,
      isVisibleTransactions_Dialog: false,
      isVisibleRemove_Dialog: false,
      valuePack: 0,
      removeValuePack: 0,
      isShowSuccessDialog: false,
      creditRow: [],
      totalCount: 1,
      isShowLogOutDialog: false
    };

    this.getLCO_Details();
    //this.getdata1()
    console.log("try", this.props.tableData);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillMount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  nextPageData(data, pageNo) {
    let lengthOfACustomerList = this.state.creditRow.length;
    // console.log("lenght of the list", this.state.totalCount);
    if (lengthOfACustomerList < this.state.totalCount) {
      // console.log("pageCount", pageNo);
      // this.setState({ animating: true });
      const { lcoID, lcoPassword, domainName } = this.state;
      API.getLcoActivity(lcoID, lcoPassword, "stbactivity", pageNo, domainName)

        .then(response => response.json())
        .then(responsejson => {
          if (responsejson.count) {
            console.log("SearchResponse", responsejson);
            let oldData = data;
            let newData = oldData.concat(responsejson.content);
            let count = this.state.pageCount + 1;

            this.setState({
              animating: false,
              isShowSearchList: true,
              creditRow: newData,
              pageCount: count
            });
            // console.log("data", this.state.customerList);
            // if (newData.length < responsejson.count) {

            //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
            // }
          }
        })
        .catch(err => {
          console.log("error", err);
          this.setState({ isSpinnerVisible: false });
          // break
        });
    }
  }
  getdataPage3(data) {
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLcoActivity(lcoID, lcoPassword, "stbactivity", 3, domainName)
      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        // if (newData.length < responsejson.count) {

        // this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
        this.setState({
          isSpinnerVisible: false,
          isShowSearchList: true,
          creditRow: newData
        });
        // } else {
        //     this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
        // }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdataPage2(data) {
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLcoActivity(lcoID, lcoPassword, "stbactivity", 2, domainName)

      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        if (newData.length < responsejson.count) {
          this.getdataPage3(newData);
        } else {
          this.setState({
            isSpinnerVisible: false,
            isShowSearchList: true,
            creditRow: newData
          });
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdata1() {
    this.setState({ isSpinnerVisible: true });
    let data = [];
    // if(this.state.initialPageCount <=this.state.totalPageCount){

    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLcoActivity(lcoID, lcoPassword, "stbactivity", 1, domainName)

      .then(response => response.json())
      .then(responsejson => {
        console.log("SearchResponse", responsejson);
        if (responsejson.message != "no data") {
          let page1Data = [];
          page1Data = responsejson.content;
          console.log("count", page1Data.length);
          this.setState({ totalCount: responsejson.count });
          if (page1Data.length < responsejson.count) {
            this.getdataPage2(page1Data);
          } else {
            this.setState({
              isSpinnerVisible: false,
              isShowSearchList: true,
              creditRow: responsejson.content
            });
          }

          //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });
        } else {
          this.setState({ isSpinnerVisible: false, isShowSearchList: false });
          // break
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  handleScroll(e) {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    console.log(
      "bottom",
      bottom,
      "e.target.scrollHeight:",
      e.target.scrollHeight,
      "e.target.scrollTop:",
      e.target.scrollTop,
      "e.target.clientHeight:",
      e.target.clientHeight
    );
    if (bottom) {
      this.nextPageData(this.state.creditRow, this.state.pageCount + 1);
    }
  }

  getLCO_Details() {
    debugger;
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode
        });
        this.getdata1();
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }

  render() {
    return (
      <Rootcontainer onScroll={e => this.handleScroll(e)}>
        <LoadingOverlay
          className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.isSpinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        <div className="header-div">
          <MdArrowBack
            onClick={() => this.backTOTrandactions()}
            className="back-button"
          />
          <h3 style={{ marginLeft: "98px" }}>Activity</h3>
          <img
            src={logo}
            style={{ float: "left", marginLeft: " -88px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutDialog: true });
            }}
            value="Logout"
            className="fontSize login"
          />
        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}
            </RootValue>
            <div className="lcocode">
              (<span style={{ color: "black" }}>Lco code:</span>{" "}
              {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>
        <MainContainer>
          <table className="table table-striped">
            <thead>
              <th>Packages</th>
              <th>Created</th>
              <th>STB</th>
              <th>Action</th>
              <th>Message</th>
              <th>Exception</th>
              <th>Status</th>
            </thead>
            <tbody className="total-records">
              {this.state.creditRow.map((data, i) => {
                return (
                  <tr>
                    <td>{data.product.length ? data.product : "Null"}</td>
                    <td>{data.created}</td>
                    <td>{data.stb}</td>
                    <td>{data.action}</td>
                    <td>{data.message ? data.message : "Null"}</td>
                    <td>{data.exception}</td>
                    <td
                      style={{ color: data.status == "fail" ? "red" : "green" }}
                    >
                      {data.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </MainContainer>

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
