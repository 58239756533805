import React from "react";
import Drawer from "react-drag-drawer";
import "../../screens/CustomerDetails/index.css";
import { FaSearch } from "react-icons/fa";
import { MdClose, MdAddCircle, MdTransferWithinAStation } from "react-icons/md";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading,
  SelectedPackage,
  SelectedDiv,
  BalanceDiv,
  CurrentBal,
  TotalBal,
  AvailableBal
} from "./styled";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,
  InputGroup,
  Form,
  Card,
  Row,
  Col
} from "react-bootstrap";
export default class AddPack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }
  toggle = () => {
    let { toggle } = this.state;

    this.setState({ toggle: !toggle });
  };
  render() { 
    if (this.props.isVisibleTransactions_Dialog) {
      return (
        <Drawer
          open={this.props.isVisibleTransactions_Dialog}
          onRequestClose={this.toggle}
          direction="left"
          className="popupwidth"
        >
          {/* <div>Hey Im inside a drawer!</div> */}

          <div
            style={{
              overflow: "auto",
              background: "#ffffff",
              display: "table"
            }}
          >
            <label style={{ fontSize: 15, fontWeight: "bold", color: "red" }}>
              {this.props.isEmptyErrorInAddPackDialog}
            </label>

            <AddPackageHeading>
              <MdTransferWithinAStation className="popup-icon" />
              Transaction Details
            </AddPackageHeading>

            <div style={{ borderTop: "1px solid #f1f1f1", padding: 21 }}>
              <table className="table table-striped">
                <thead>
                  <td>Product Name</td>
                  <td style={{ width: "14%" }}>STB No</td>
                  <td>Type</td>
                  <td style={{ width: "13%" }}>VC No</td>
                  <td>Status</td>
                  <td>Amount</td>
                  <td>Tax Amount</td>
                  <td>Total Amount</td>
                  <td>Date</td>
                  <td>Colleted BY</td>
                  <td>Colleted Date</td>
                </thead>
                <tbody>
                  {this.props.allTransactionsResult.map((data, i) => {
                    debugger;
                    console.log("all data", data.Products);
                    return (
                      <tr>
                        {/* <td style={{ color: "#ea3232" }}>{data.Products}</td> */}
                        <td>{data.Products}</td>
                        <td style={{ width: "14%" }}>{data.STBNo}</td>
                        <td>{data.type ? data.type : "None"}</td>
                        <td style={{ width: "13%" }}>{data.VCNo}</td>
                        <td
                          style={{
                            color: data.Paid == "UnPaid" ? "red" : "green"
                          }}
                        >
                          {data.Paid}
                        </td>
                        <td>
                          {"\u20B9"} {data.amount}
                        </td>
                        <td>
                          {"\u20B9"} {data.taxamount}
                        </td>
                        <td>{data.totalamount}</td>
                        <td>{data.Created}</td>
                        <td>{data.collected_by ? data.collected_by : "None"}</td>
                        <td>{data.Collected ? data.Collected : "None"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-div" style={{ marginTop: "-28px" }}>
              <Button
                variant="danger"
                className="pull-left mt-1 mr-3 ml-3"
                onClick={() => {
                  this.props.inVisibleAllTransactionDialog();
                }}
              >
                {" "}
                Close
              </Button>
            </div>
          </div>
        </Drawer>
      );
    } else {
      return null;
    }
  }
}
