import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card,
  Col,
  Row
} from "react-bootstrap";

import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  SelectedDiv,
  SelectedPackage
} from "./styled";
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import { FaPenSquare } from "react-icons/fa";
import axios from "axios";
import {
  MdSearch,
  MdPowerSettingsNew,
  MdAddCircle,
  MdRemoveCircle,
  MdNotifications,
  MdTransferWithinAStation,
  MdKeyboardReturn,
  MdPeople
} from "react-icons/md";
import DialogBoxs from "./DialogBox";

export default class Instock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      isShowLogOutWarning: false
    };
  }
  hindLogOutDialog() {
    this.setState({ isShowLogOutWarning: false });
  }

  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  getCSVFile() {
    // var url1=  'https://my.jaknetwork.in/stb/dumptinvoice/?download=yes&lcocode=TEST001&d1=2019-09-13&d2=2019-09-17';
    // fetch(url1,{
    //   method: 'GET',
    //   responseType: 'blob'
    // }).then((response) => {
    //   console.log("repose",response);
    //   debugger;
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'download.xlsx');
    //   link.click();
    //   window.URL.revokeObjectURL(url);
    // });

    axios({
      url:
        "https://my.jaknetwork.in/stb/dumptinvoice/?download=yes&lcocode=TEST001&d1=2019-09-13&d2=2019-09-14",
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      console.log("data", response);
      debugger;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }

  // logOutFunc() {
  //     if (this.state.isloggedOut) {
  //         return <Redirect to="/login" />
  //     } else {
  //         return null;
  //     }
  // }
  // logOut() {

  //     localStorage.removeItem("userID");
  //     const username = localStorage.getItem("userID");
  //     if (!username) {
  //         this.setState({ isloggedOut: true });
  //     }
  // }
  render() {
    return (
      <Rootcontainer
        onScroll={e => this.handleScroll(e)}
        // ref={this.nav}
        //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
      >
        <div className="header-div">
          <h3 className="" style={{ marginLeft: "98px" }}>
            Stock Operation
          </h3>
          <img
            src={logo}
            style={{ float: "left", marginLeft: " -180px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutWarning: true });
            }}
            value="Logout"
            className="fontSize login"
          />
        </div>

        <MainContainer>
          {/* <Container>
            <div className="d-flex flex-column transactions-btn">
              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToSTBCreation();
                  }}
                >
                  <MdAddCircle />
                  STB Creation
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToSTBTransfer();
                  }}
                >
                  <MdRemoveCircle />
                  Transfer
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  moveToSTBReturns
                  onClick={() => {
                    this.props.moveToSTBReturns();
                  }}
                >
                  <MdNotifications />
                  Returns
                </Button>
              </ButtonGroup>

              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToCustomerTransfer();
                  }}
                >
                  <MdAddCircle />
                  Customer Transfer
                </Button>
              </ButtonGroup>
            </div>
          </Container> */}

          <Container className="mt-5">
            <Row className="transcation">
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBCreation();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #add100, #7b920a)"
                  }}
                >
                  <div className="circle">
                    <FaPenSquare
                      style={{
                        background:
                          "linear-gradient(to right, rgb(161, 194, 2), rgb(135, 161, 7))"
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      color: "#fff"
                      // marginTop: "-5px"

                      // marginBottom:30
                    }}
                  >
                    STB Creation
                  </Card.Text>
                </Card>
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBTransfer();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #9d50bb, #6e48aa)"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background:
                          "linear-gradient(to right, rgb(144, 78, 182), rgb(120, 74, 174))"
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      color: "#fff"
                      // marginTop: "-5px"
                      // marginBottom: 30
                    }}
                  >
                    Transfer
                  </Card.Text>
                </Card>
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBReturns();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #136a8a, #267871)"
                  }}
                >
                  <div className="circle">
                    <MdKeyboardReturn
                      style={{
                        background:
                          " linear-gradient(to right, rgb(26, 111, 130), rgb(33, 117, 118))"
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      color: "#fff"
                      // marginTop: "-5px"
                      // marginBottom: 30
                    }}
                  >
                    Returns
                  </Card.Text>
                </Card>
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToCustomerTransfer();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: " linear-gradient(to right, #cb356b, #bd3f32)"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background:
                          "linear-gradient(to right, rgb(199, 56, 90), rgb(191, 62, 58))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Customer Transfer
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToCreateCustomerWithSTB();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: " linear-gradient(to right, #f46b45, #eea849)"
                  }}
                >
                  <div className="circle">
                    <MdPeople
                      style={{
                        background:
                          "linear-gradient(to right, rgb(243, 125, 70), rgb(240, 150, 72))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Bulk customer and settop box Creation
                  </Card.Text>
                </Card>
              </Col>

              {/* <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                   this.props.moveToBulkPackAddandRemove();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      " linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background:
                          "linear-gradient(to right, rgb(255, 67, 91), rgb(255, 73, 54))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Bulk package Add and Remove
                  </Card.Text>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </MainContainer>
        <DialogBoxs
          isShowLogOutWarning={this.state.isShowLogOutWarning}
          logOut={this.logOut.bind(this)}
          hindLogOutDialog={this.hindLogOutDialog.bind(this)}
        />

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
