import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import  transfergif from "../../assets/transfer.gif"
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLco: {},
            isShowTransferWarning: false,
            STBList:"",
            extendValue: 30,
            isShowWarningForExtend: false,
            isShowBalance:false
        }
    }

   
    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red" : "green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindProcessDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }
    isShowLowBalance(price) {
        var req = parseInt(price);
        var bal = parseInt(this.props.lcoBalance);
        if (req > bal) {
            return (
                <div>
                    <label style={{ fontWeight: "bold", color: "red" }}>Low Balance !!</label>
                </div>
            );
        } else {
            return null;
        }
    }
    render() {
        var extendss = this.state.extendValue;
        var price = ((this.props.totalValueOfSelectedSTB / 30) * extendss).toFixed(2);
        // debugger;
        // var data = price * 0.18;
        // price = (parseFloat(price) + parseFloat(data)).toFixed(2);
        return (
            <div>


                <Drawer

                    open={this.props.isShowLogOutWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Log Out</span> ?</div>
                            <Undraw className="undrawimg" name="monitor" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindLogOutDialog();

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowProcessDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">

                            <div style={{ fontSize: 18, marginLeft: 20, color: "red", fontWeight: "bold", textAlign: "center" }}>
                                {this.props.noOfcompletedData}
                                <span style={{ fontSize: 18, color: "black" }}> of </span>
                                {this.props.noOfDataGoingToProcess}
                                <span style={{ fontSize: 18, color: "black" }}> is Completed Successfully </span>
                            </div>
                            <img src={transfergif} alt="loader" style={{width:461,height: 263,  display:this.props.noOfcompletedData == this.props.noOfDataGoingToProcess? 'none' :'block',marginTop: 15,}}/>
                            <Undraw
                                style={{ marginTop: 10,
                                     display:this.props.noOfcompletedData == this.props.noOfDataGoingToProcess? 'block':'none', 
                                     width: 461, marginBottom: 10, height: 263 }}
                                name={this.props.noOfcompletedData == this.props.noOfDataGoingToProcess ? "new-message" : "co-working"} />

                            {this.showOkBtnInProcessing()}





                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowPayNowWarningDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card style={{ maxWidth: "80vh" }}>


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18 }}> PAY NOW</div>
                            <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Pay now</span> ?</div>

                            <Undraw
                                style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
                                name="connected" />



                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindPayNowWarningDialog();

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindPayNowWarningDialog();
                                    this.props.payFunc();
                                 


                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowSelectedSTBListDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card style={{ maxWidth: "80vh" }}>


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18 }}> Selected STB list:</div>
                            <div style={{ marginLeft: "30px", marginTop: 10 }}> No of selected STB :<span style={{ fontWeight: "bold", color: "red" }}> {this.props.SelectedDataList.length}</span> </div>

                           <div style={{marginLeft:25,overflow:"auto",maxHeight:"30vh"}}>
                           {this.props.SelectedDataList.map((item,i)=>{
                               return(
                                   <div > {item.STB}</div>
                               );
                           })}

                           </div>



                           
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindSelectedSTBListDialog();
                                 


                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

open={this.props.isShowUnProcessedDataListDialog}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Unable To process this STB List:</div>

        <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: "rgb(26, 172, 195)", overflow: "auto",marginTop:20 }}>
            {this.props.unProcessedData.map((data, i) => {
                return (
                    <div style={{ fontSize: 15, marginTop: 10, fontWeight: "bold",marginLeft:20 }}> {i + 1}.)  {data.result}</div>
                )
            })}
        </div>


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindUnProcessedDataListDialog();

            }}
        >OK</Button>



    </Card.Body>
</Card>


</Drawer>
<Drawer

open={this.props.isEmptyList}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Please select STB </div>

        <Undraw
            style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
            name="empty" />


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindEmptyList();

            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>


<Drawer

open={this.props.isLowBalance}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Low Balance </div>

        <Undraw
            style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
            name="warning" />


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindLowBalance();

            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>


<Drawer

                    open={this.props.isShowWarningForDisconnect}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Disconnect</div>
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Disconnect </span>?</div>
                            <div style={{ textAlign: "end" }}>
                                <Undraw
                                    style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                                    name="warning" />
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForDisconnect();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindWarningForDisconnect();
                                        this.props.DisconnetFunc();

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.props.isShowBulkSearch}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>STB List</div>
                            
                            <div style={{ textAlign: "end" }}>
                               <textarea 
                               style={{width:300,height:500,display:"block",alignItems:"flex-start"}}
                               multiple={true}
                               placeholder="Enter the STBs"
                               onChange={(e)=>{this.setState({STBList:e.target.value})}}
                               />
                                <Button
                                    style={{ marginRight: 15, backgroundColor: "red" }}
                                    className="danger" className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindBulkSearch();

                                    }}
                                >Cancel</Button>
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.bulkSearchFunc(this.state.STBList);
                                        this.props.hindBulkSearch();
                                        

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>



                <Drawer

                    open={this.props.isShowInvalidSTBListInBulkSearch}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>Unprocess Data</div>
                            <div style={{fontSize:15}}>No of STBs: <span style={{color:"red",fontSize:18,fontWeight:"bold"}}>{this.props.isInValidDataList.length}</span></div>
                            <div style={{marginLeft:20,overflow:"auto",maxHeight:"40vh"}}>
                              {this.props.isInValidDataList.map((item)=>{
                                  return(
                                      <div style={{fontSize:18}}>{item}</div>
                                  );
                              })}
                               
                                <Button

                                    style={{ marginRight: 15, backgroundColor: "green" }}
                                    className="success" className="mt-3 pull-right"
                                    onClick={() => {
                                        this.props.hindInvalidSTBListInBulkSearch();
                                        

                                    }}
                                >OK</Button>
                            </div>




                        </Card.Body>
                    </Card>

                </Drawer>




                <Drawer

                    open={this.props.isShowExtendDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            {this.isShowLowBalance(price)}
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
                            <div style={{ fontSize: 13, marginTop: 10 }}>How many day<span style={{ color: "red", fontWeight: "bold" }}> extends </span> ?</div>
                            <div style={{ textAlign: "end", marginRight: 15 }}>Amount : <span style={{ fontWeight: "bold", color: "red" }}>{'\u20B9'} {price}</span></div>

                            <input type="number" value={this.state.extendValue}
                                onChange={(e) => { this.setState({ extendValue: e.target.value }) }}
                                style={{ width: "40vh", marginTop: 10, border: "2px solid gray", padding: 5, }}
                            />
                            <div style={{ marginTop: 15, textAlign: "end" }}>
                                <Button
                                    style={{ backgroundColor: "red", borderColor: "red" }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.props.hindExtendDialog();

                                    }}
                                >cancel</Button>
                                <Button
                                    style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                    className="mt-3 pull-right"
                                    onClick={() => {

                                        this.setState({ isShowWarningForExtend: true });

                                    }}
                                >OK</Button>
                            </div>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isShowWarningForExtend}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold" }}>  Extends</div>
                            <div style={{ fontSize: 13, marginLeft: 36, marginBottom: 20, marginTop: 5 }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>Extend</span> ?</div>
                            <Undraw
                                style={{ marginTop: 10, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: 79 }}
                                name="add-files" />

                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowWarningForExtend: false });

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isShowWarningForExtend: false });
                                    this.props.hindExtendDialog();
                                    this.props.ExtendFunc(this.state.extendValue);

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>



                <Drawer

open={this.props.isShowSuspendWarning}
onRequestClose={this.toggle}
direction='left'
>

<Card style={{ maxWidth: "80vh" }}>


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18 }}> SUSPEND</div>
        <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Suspend</span> ?</div>

        <Undraw
        primaryColor="red"
            style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
            name="cancel" />



        <Button
            style={{ backgroundColor: "red", borderColor: "red" }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindSuspendWarning();

            }}
        >cancel</Button>
        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {
                this.props.hindSuspendWarning();
                this.props.SuspendFunc();
             


            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>


<Drawer

open={this.props.isShowResumeWarning}
onRequestClose={this.toggle}
direction='left'
>

<Card style={{ maxWidth: "80vh" }}>


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18 }}> RESUME</div>
        <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Resume</span> ?</div>

        <Undraw
        primaryColor="green"
            style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
            name="connected" />



        <Button
            style={{ backgroundColor: "red", borderColor: "red" }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindResumeWarning();

            }}
        >cancel</Button>
        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {
                this.props.hindResumeWarning();
                this.props.ResumeFunc();
             


            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>

              














            </div>
        );
    }
}