import React from "react";
// import {Button,ButtonGroup,Container,Card} from 'react-bootstrap';
import {
  Card,
  ButtonGroup,
  Button,
  Col,
  Row,
  Container
} from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,

  AddPackageHeading
} from "./styled";
import API from "../../Api";

import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../assets/loding-gif.gif";
import "../../screens/Transactions/index.css";
import Drawer from "react-drag-drawer";
import {
  MdPowerSettingsNew,
  MdCreditCard,
  MdAddCircle,
  MdRemoveCircle,
  MdNotifications,
  MdInsertDriveFile,
  MdPersonPin,
  MdTrendingUp
} from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { FaCcMastercard, FaUserPlus, FaCloudDownloadAlt } from "react-icons/fa";
import Undraw from "react-undraw";
import DialogBoxes from './DialogBoxes_index';
// import GPayButton from 'react-google-pay-button'

// const paymentMethods = [
//   {
//     type: 'CARD',
//     phoneNumberRequired: true,
//     parameters: {
//       allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
//       allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
//     },
//     tokenizationSpecification: {
//       type: 'PAYMENT_GATEWAY',
//       parameters: {
//         'gateway': 'stripe',
//         'stripe:version': '2019-03-14',
//         'stripe:publishableKey': '<YOUR_PUBLIC_STRIPE_KEY>'
//       }
//     }
//   },
//   {
//     type: 'PAYPAL',
//     parameters: {
//       'purchase_context': {
//         'purchase_units': [{
//           'payee': {
//             'merchant_id': '0123456789'
//           }
//         }]
//       }
//     },
//     tokenizationSpecification: {
//       type: 'DIRECT'
//     }
//   }
// ]
export default class index extends React.Component {
  constructor(props) {
    super(props);
    console.log("data from search", this.props.specifyUserDetails);
    const data = this.props.specifyUserDetails;
    console.log("data in customer details", data);
    console.log("data console", this.props.onClickCredit);
    this.state = {
      userMetaData: data,
      userSTBDetails: {},
      currentChecked: [],
      currentCheckedRemove: [],
      currentPack: [],
      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,
      addPackageSearchResultList: [],
      allTransactionsResult: [],
      spinnerVisible: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      isShowLogOutDialog: false,
      isShowRechargeWarningDialog: false,
      paygateway: ""
    };

    this.getLCO_Details();
  }

  hindRechargeWarningDialog() {
    this.setState({ isShowRechargeWarningDialog: false });
  }
  getLCO_Details() {
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName, lcoBalance } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        console.log("repose", responsejson);
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode,
          isSpinnerVisible: false,
          paygateway: responsejson.paygateway
        });
        console.log("lcoName", lcoBalance);
      });
  }
  moveToCredit() {
    this.props.onClickCredit();
  }
  moveToDebit() {
    console.log("debit log", this.props.onClickDebit);
    this.props.onClickDebit();
  }
  moveToNotifications() {
    this.props.onClickNotifications();
  }

  moveToActivityLog() {
    const { lcoID, lcoPassword, domainName, lcoBalance } = this.state;
    API.getLcoActivity(lcoID, lcoPassword, "stbactivity", 1, domainName)
      .then(response => response.json())
      .then(responseJson => {
        console.log("response in Activity log", responseJson);
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }


  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        <div className="header-div">
          <h3 style={{ marginLeft: "98px" }}>Transactions</h3>
          <img
            src={logo}
            style={{ float: "left", marginLeft: " -140px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutDialog: true });
            }}
            value="Logout"
            className="fontSize login"
          />
        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}
            </RootValue>
            <div className="lcocode">
              (<span style={{ color: "black" }}>Lco code:</span>{" "}
              {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>

        <MainContainer>
          {/* <Container>
            <div className="d-flex flex-column transactions-btn">
              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToCredit();
                  }}
                >
                  <MdAddCircle />
                  Credit
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToDebit();
                  }}
                >
                  <MdRemoveCircle />
                  Debit
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.moveToNotifications();
                  }}
                >
                  <MdNotifications />
                  Notifications
                </Button>
              </ButtonGroup>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ marginTop: 30, float: "left", width: "30%" }}>
                  <Button
                    onClick={() => {
                      this.props.onClickProfile();
                    }}
                    style={{ width: "100%", color: "#802915", height: 126 }}
                    variant="light"
                  >
                    <MdPersonPin />
                    Lco Details
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: 30,
                    float: "left",
                    width: "30%",
                    marginLeft: 30
                  }}
                >
                  <Button
                    onClick={() => {
                      this.props.onClickActivityLog();
                    }}
                    style={{
                      width: "100%",
                      color: "#2E4172",
                      height: 126,
                      float: "left"
                    }}
                    variant="light"
                  >
                    <MdTrendingUp />
                    Activity
                  </Button>
                </div>
              </div>
            </div>
          </Container> */}

          <Container className="mt-4">
            <Row className="transcation">
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToCredit();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(255, 78, 80), rgb(249, 212, 35))"
                  }}
                >
                  <div className="circle">
                    <MdCreditCard
                      style={{
                        background:
                          "linear-gradient(to right, rgb(253, 116, 67), rgb(251, 170, 49))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Credit
                  </Card.Text>
                </Card>
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToDebit();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #4b6cb7, #182848)"
                  }}
                >
                  <div className="circle">
                    <FaCcMastercard
                      style={{
                        background:
                          "linear-gradient(to right, rgb(60, 88, 150), rgb(37, 57, 100))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Debit
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.onClickProfile();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                  }}
                >
                  <div className="circle">
                    <MdPersonPin
                      style={{
                        background:
                          "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Lco Details
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.moveToNotifications();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(244, 107, 69), rgb(238, 168, 73))"
                  }}
                >
                  <div className="circle">
                    <MdNotifications
                      style={{
                        background:
                          " linear-gradient(to right, rgb(242, 125, 70), rgb(240, 150, 72))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                    Notifications
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.onClickActivityLog();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #06beb6, #48b1bf)"
                  }}
                >
                  <div className="circle">
                    <MdTrendingUp
                      style={{
                        background:
                          "linear-gradient(to right, rgb(29, 185, 185), rgb(58, 180, 189))"
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      color: "#fff"
                      //  marginTop: "-15px"
                    }}
                  >
                    Activity
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToUnmappedSTB();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      "linear-gradient(to right, rgb(173, 83, 137), rgb(60, 16, 83))"
                  }}
                >
                  <div className="circle">
                    {/* <MdTrendingUp
                      style={{
                        background:
                          "linear-gradient(to right, rgb(51, 157, 199), rgb(66, 113, 177))"
                      }}
                    /> */}
                    <FaUserPlus
                      style={{
                        padding: 12,
                        background:
                          "linear-gradient(to right, rgb(133, 60, 118), rgb(92, 35, 98))"
                      }}
                    />
                  </div>

                  <Card.Text
                    style={{
                      color: "#fff"
                      // marginTop: "-15px"
                    }}
                  >
                    Add Customer
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToReport();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #11998e, #38ef7d)"
                  }}
                >
                  <div className="circle">
                    <FaCloudDownloadAlt
                      style={{
                        padding: 12,
                        background:
                          "linear-gradient(to right, rgb(30, 182, 136), rgb(46, 218, 129))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-22px" }}>
                    Download Report
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToBulkCustomerCreation();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #7f00ff, #e100ff)"
                  }}
                >
                  <div className="circle">
                    {/* <MdTrendingUp
                      style={{
                        background:
                          "linear-gradient(to right, rgb(51, 157, 199), rgb(66, 113, 177))"
                      }}
                    /> */}
                    <IoIosSettings
                      style={{
                        padding: 12,
                        background:
                          "linear-gradient(to right, rgb(157, 0, 255), rgb(198, 0, 255))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-22px" }}>
                    Bulk customer creation
                  </Card.Text>
                </Card>
              </Col>

              <Col
                md={3}
                sm={12}
                style={{
                  margin: "10px 0px 50px",
                  cursor: "pointer",
                  display: this.state.paygateway !== "" ? "block" : "none"
                }}
                onClick={() => {
                  this.setState({ isShowRechargeWarningDialog: true });
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background: "linear-gradient(to right, #7f0055, #e10055)"
                  }}
                >
                  <div className="circle">
                    {/* <MdTrendingUp
                      style={{
                        background:
                          "linear-gradient(to right, rgb(51, 157, 199), rgb(66, 113, 177))"
                      }}
                    /> */}
                    <IoIosSettings
                      style={{
                        padding: 12,
                        background:
                          "linear-gradient(to right, rgb(160, 40, 84), rgb(192, 48, 85))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-22px" }}>
                    Balance Recharge
                  </Card.Text>
                </Card>
              </Col>


            </Row>
          </Container>


          <DialogBoxes
            lcocode={this.state.lcocode}
            lcoID={this.state.lcoID}
            isShowRechargeWarningDialog={this.state.isShowRechargeWarningDialog}
            hindRechargeWarningDialog={this.hindRechargeWarningDialog.bind(this)}
            paygateway={this.state.paygateway}

          />
          <div
            style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
          >

            {/* <img scr={loadinggif} style={{width:500,height:400}} alt="data image"></img> */}
            Build 5.29
          </div>
        </MainContainer>
        {this.logOutFunc()}

      </Rootcontainer>
    );
  }
}
