import React, { Component } from "react";
import logo from "../../assets/logo-3.png";
import "../login/index.css";
import "./login.css";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button
} from "react-bootstrap";

import { Redirect } from "react-router-dom";
import API from "../../Api";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../assets/loding-gif.gif";
import BounceLoader from "react-spinners/BounceLoader";
import "../../assets/index.css";
import Undraw from "react-undraw";
// import DialogBoxes from "./DialogBoxes";
export default class index extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      userName: "",
      userPassword: "",
      domain: "",
      isSubmitted: false,
      errorMessage: "",
      spinnerVisible: false
    };
    // console.log("props",props);
    // if(props.match.params.id){
    //   // this.setState({domain:props.match.params.id})
    //   debugger;
    // }
  }
  // componentWillMount(props){
  //   console.log("props",props);
  // }

  submit(e) {
    e.preventDefault();
    //  localStorage.setItem("userID",this.state.userName);
    //  console.log("user",localStorage.getItem("userID"));
    //  this.setState({isSubmitted:true});
    const { userName, userPassword, domain } = this.state;
    console.log("data", userName, userPassword, domain);
    localStorage.removeItem("userID");
    localStorage.removeItem("userPassword");
    localStorage.removeItem("domain");
    if (userName && userPassword && domain) {
      this.setState({ spinnerVisible: true });
      API.signInAPI(userName, userPassword, domain)
        .then(response => response.json())
        .then(responsejson => {
          console.log("responsejson", responsejson);
          if (responsejson.message != "error user not authenticated") {
            localStorage.setItem("userID", this.state.userName);
            localStorage.setItem("userPassword", this.state.userPassword);
            localStorage.setItem("domain", this.state.domain);
            localStorage.setItem(
              "lcolist",
              JSON.stringify(responsejson.lcocode)
            );
            localStorage.setItem("currentlco", responsejson.lcocode[0].lcocode);
            // stock
            localStorage.setItem("isShowStock", responsejson.stock);

            this.setState({ isSubmitted: true, spinnerVisible: false });
          } else {
            this.setState({
              errorMessage: responsejson.message,
              spinnerVisible: false
            });
          }
        })
        .catch(error => {
          this.setState({ errorMessage: "unable to process" });
          this.setState({ spinnerVisible: false });
        });
    }
  }
  isSubmitSuccess() {
    if (this.state.isSubmitted) {
      return <Redirect to="/home" />;
    } else {
      return null;
    }
  }
  render() {
    return (
      <React.Fragment>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          //overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              alt="logo"
              src={loadinggif}
              className="spinnerContent"
              color="green"
            />
          }
        ></LoadingOverlay>

        {/* form  */}
        <div id="login-form">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                  <div className="card-body">
                    <img src={logo} className="login-logo" alt="logo" />
                    <h5 className="card-title text-center">Log In </h5>

                    <form className="form-signin">
                      <div className="form-label-group">
                        {/* <input
                          type="name"
                          id="DomainName"
                          className="form-control"
                          placeholder=" Domain"
                          required
                        /> */}
                        <input
                          onChange={e => {
                            this.setState({ domain: e.target.value });
                          }}
                          className="form-control"
                          id="DomainName"
                          type="name"
                          placeholder=" Domain"
                          value={this.state.domain}
                        />
                        <label htmlFor="DomainName">Domain</label>
                      </div>
                      <div className="form-label-group">
                        {/* <input
                          type="name"
                          id="StbNumber"
                          className="form-control"
                          placeholder="STB Number"
                          required
                        /> */}
                        <input
                          onChange={e => {
                            this.setState({ userName: e.target.value });
                          }}
                          id="StbNumber"
                          className="form-control"
                          name="Username"
                          placeholder="User Name"
                          value={this.state.userName}
                        />
                        <label htmlFor="StbNumber">User Name</label>
                      </div>
                      <div className="form-label-group">
                        <input
                          onChange={e => {
                            this.setState({ userPassword: e.target.value });
                          }}
                          value={this.state.userPassword}
                          id="Password"
                          className="form-control"
                          type="password"
                          placeholder="Enter Password"
                        />
                        <label htmlFor="Password">Password </label>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <label
                          style={{
                            fontSize: 15,
                            color: "red"
                          }}
                        >
                          {this.state.errorMessage}
                        </label>
                      </div>

                      <button
                        onClick={e => {
                          this.submit(e);
                        }}
                        className="btn btn-lg btn-primary btn-block text-uppercase"
                      >
                        Log In
                      </button>
                      <h6 className="mt-4 text-center">
                        <a
                          style={{ textDecoration: "none", color: "gray" }}
                          href="https://impactsms.in/" target="_blank">
                          Powered by Impact sms
                        </a>
                      </h6>
                      <div style={{ textAlign: "center" }}>

                        <label>Version 5.29</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.isSubmitSuccess()}
      </React.Fragment>
    );
  }
}
