

// const URL = "https://sms.balajidigitalmdl.com/api/mobilelogin/";
// const URL2 = "https://sms.balajidigitalmdl.com/api/customerno/";
const localHost ="http://192.168.0.179:8000"
const base=localStorage.getItem("domain")
const AuthID = "b5c68648e19a28d36b6f10f8afd7a9bf724e48e5";
const mobileUrl =`${localStorage.getItem("domain")}/api/mobilelogin/`;
const customerUrl =`${localStorage.getItem("domain")}/api/customerno/`;
const stockUrl = `${localStorage.getItem("domain")}/api/stock/`





export const SEARCH_COMMAND = "search";




const API = {
    // SignIn
    signInAPI(UserName, Password, domainName) {

        const body = JSON.stringify({
            "username": UserName,
            "password": Password,
            "command": "auth",

        });
        return fetch(`${domainName}/api/mobilelogin/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID
            },
            body
        });
    },
    searchAPI(userName, userPassword, command, searchText, page,domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }

       

        const body = JSON.stringify({
            "username": userName,
            "password": userPassword,
            "command": "basicsearch",
            "search": searchText,
            "page": page,
            "draw":15,
            "lcocode":localStorage.getItem("currentlco")
        });
        
        const URL = domainName + "/api/mobilelogin/";
   
        return fetch(URL,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID
            },
            body
        });
    },
    payNowAPI(userName, userPassword, customerNo, command, domainName) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            customerno: customerNo,
            command: command,
            lcocode:localStorage.getItem("currentlco")
        });

       
   var URL=domainName+"/api/customerno/";
        return fetch(URL,{
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    allTransactionsAPI(userName, userPassword, customerNo, command, domainName, pageNo) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: customerNo,
            page: pageNo,
            lcocode:localStorage.getItem("currentlco")

        });

       
        var URL=domainName+"/api/customerno/";
        return fetch(URL,{
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    LCOCreditsAPI(userName, userPassword, command, pageNo, domainName) {


        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
     
        ;

        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            page: pageNo,
            lcocode:localStorage.getItem("currentlco")

        });
       
        const url=domainName+"/api/mobilelogin/"
        return fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    removePackageAPI(userName, userPassword, customerNo, command, std, packageId, domainName) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            customerno: customerNo,
            command: command,
            stb: std,
            packageid: packageId,
            lcocode:localStorage.getItem("currentlco")
        });

        
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getPackageAPI(userName, userPassword, command, std, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
        
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            stb: std,
            lcocode:localStorage.getItem("currentlco")
        });

      
   var url=domainName+"/api/mobilelogin/"
        return fetch(url,{
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    addPackageAPI(userName, userPassword, customerNo, command, std, packageId, domainName) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            customerno: customerNo,
            command: command,
            stb: std,
            packageid: packageId,
            lcocode:localStorage.getItem("currentlco")
        });

       
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    dashboardAPI(userName, userPassword, command, domainName) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
    
        const URL = domainName + "/api/mobilelogin/";
      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco")
        });

      
      

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });
    },
    singleCustomerDetailsAPI(userName, userPassword, command, searchID, page, domainName) {
        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
        
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            search: searchID,
            page: page,
            lcocode:localStorage.getItem("currentlco")
        });
        

       
        const URL = domainName + "/api/mobilelogin/";
      

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });
    },
    getLOC_Details(userName, userPassword, command, domainName) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco")
        });

       
        const URL = domainName + "/api/mobilelogin/";
      

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });
    },
    refreshCustomerData(userName, userPassword, command, domainName, customerNo) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            customerno: customerNo,
            command: command,
            lcocode:localStorage.getItem("currentlco")

        });

       
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });
    },
    getUnMappedSTB(userName, userPassword, command, domainName) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco")

        });

       
        const URL = domainName + "/api/mobilelogin/";
        

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });

    },
    getRemoveSTB(userName, userPassword, command, domainName, customernum, stbno) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: customernum,
            stb: stbno,
            lcocode:localStorage.getItem("currentlco")

        });

        
        const URL = domainName + "/api/customerno/";
      
        
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });

    },
    getaddSTB(userName, userPassword, command, domainName, customernum, stbno) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: customernum,
            stb: stbno,
            lcocode:localStorage.getItem("currentlco")

        });

        
        const URL = domainName + "/api/customerno/";
       

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });

    },
    createCustomer(userName, userPassword, command, STB, customername, installaddress, billaddress, pincode, email, mobile, tel, domainName,image,aadharNo) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            STB: STB,
            customername: customername,
            installaddress: installaddress,
            billaddress: billaddress,
            pincode: pincode,
            email: email,
            mobile: mobile,
            tel: tel,
            lcocode:localStorage.getItem("currentlco"),
            image: image,
            customeridnum: aadharNo

        });

        
        const URL = domainName + "/api/mobilelogin/";
        
    debugger;
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });
        
    },
    getLCoDebits(userName, userPassword, command, pageNo, domainName) {


        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        ;

        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            page: pageNo,
            lcocode:localStorage.getItem("currentlco")

        });
       
        var url=domainName+"/api/mobilelogin/"
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    getNotificationData(userName, userPassword, command, page, domainName) {
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            page: page,
            lcocode:localStorage.getItem("currentlco")


        });

        
        const URL = domainName + "/api/mobilelogin/";
      

        return fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthID

            },
            body
        });

    },


    bulkPayActivate(userName, userPassword, command, custno, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: custno,
            lcocode:localStorage.getItem("currentlco")
        });

       
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    bulkDisconnect(userName, userPassword, command, custno, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
        
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: custno,
            lcocode:localStorage.getItem("currentlco")
        });

      
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    disconnect(userName, userPassword, command, custno, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customerno: custno,
            lcocode:localStorage.getItem("currentlco")
        });

       
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    payAllCheck(userName, userPassword, command, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
        
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco")

        });

      
var url=domainName+"/api/mobilelogin/"
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    payAll(userName, userPassword, command, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
       
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco")

        });

        
        var url=domainName+"/api/mobilelogin/"
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    stbDetails(userName, userPassword, command, STBNo, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            stb: STBNo,
            lcocode:localStorage.getItem("currentlco")
        });

     
        const url = domainName + "/api/mobilelogin/";
      
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    inversePaySto(userName, userPassword, command, STBNo, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            custs: STBNo,
            lcocode:localStorage.getItem("currentlco")
        });

      
        const url = domainName + "/api/mobilelogin/";
       
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    STBSearch(userName, userPassword, command, STBNo, domainName) {

        // let scheme;
        // if(global.ISHTTPS){
        //    scheme="https"
        // }else{
        //     scheme="http"
        // }
     
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            search: STBNo,
            lcocode:localStorage.getItem("currentlco")
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    changePasswordAPI(userName, userPassword, command, newPassword, domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            pwd: newPassword,
            lcocode:localStorage.getItem("currentlco")
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getLcoActivity(userName, userPassword, command, pageNo, domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            page: pageNo,
            lcocode:localStorage.getItem("currentlco")
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    getSTBActivityLog(userName, userPassword, command, STB,pageNo, domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            stb:STB,
            page: pageNo,
            lcocode:localStorage.getItem("currentlco")
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    editCustomer(userName, userPassword, command, name, customernum,address,pincode,email,mobileNo,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco"),
            customername:name,
            customernum:customernum,
            address:address,
            pincode:pincode,
            email:email,
            mobile:mobileNo
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    stockListSearch(userName, userPassword, command, searchText,pageNo,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            search:searchText,
            page:pageNo
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    STBCasAndType(userName, userPassword, command, domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    createSTB(userName, userPassword, command, SNo, MACVCChipNumber,Model,type,Installdate, InstallRate, cas,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            SNo:SNo,
            MACVCChipNumber:MACVCChipNumber,
            Model:Model,
            type:type,
            Installdate:Installdate,
            InstallRate:InstallRate,
            cas:cas,
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getMSOUnmappedSTB(userName, userPassword, command, searchCommand,pageNo,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            search:searchCommand,
            page:pageNo
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    mappedSTBToLCO(userName, userPassword, command, STBNo,lcocode,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            stbno:STBNo,
            lcocode:lcocode
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    getAllUnmappedSTB(userName, userPassword, command, searchCommand,pageNo,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            search:searchCommand,
            page:pageNo
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    unmappedSTBFromLCO(userName, userPassword, command, STBNo,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            stbno:STBNo,
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

   customerTransfer(userName, userPassword, command, customernum,lcocode,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customernum:customernum,
            lcocode:lcocode
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getFullDataList(userName, userPassword, command,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco"),
            // lcocode:"JAK98616237"
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    createSTBWithCustomerAndProduct(
        userName, 
        userPassword, 
        command,
        domainName,
        Name,
        lcocodeno,
            Address,
            City,
            State,
            Mandal,
            District,
            Pincode,
            caf,
            Mobile,
            Email,
            Tax,
            Lcocustid,
            Country,
            Telno,
            STBnumber,
            MACVCChipNumber,
            Model,
            Status,
            InstallDate,
            Installrate,
            Supplier,
            Product,
            cas,
            type,
            Billingmodel,
            IDType) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command, 
            
            rows:[ {
                Name:Name,
            lcocode:lcocodeno,
                Address:Address,
                City:City,
                State:State,
                Mandal:Mandal,
                District:District,
                Pincode:Pincode,
                caf:caf,
                Mobile:Mobile,
                Email:Email,
                Tax:Tax,
                Lcocustid:Lcocustid,
                Country:Country,
                Telno:Telno,
                Sno:STBnumber,
                MACVCChipNumber:MACVCChipNumber,
                Model:Model,
                Status:Status,
                InstallDate:InstallDate,
                Installrate:Installrate,
                Supplier:Supplier,
                Product:Product,
                cas:cas,
                type:type,
                Billingmodel:Billingmodel,
                IDType:IDType
                }]
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    createCustomerAndProduct(
        userName, 
        userPassword, 
        command,
        domainName,
        STB,
        customername,
        installaddress,
        billaddress,
        pincode,
        email,
        mobile,
        tel,
        Products
       ) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            lcocode:localStorage.getItem("currentlco"), 
            STB:STB,
            customername:customername,
            installaddress:installaddress,
            billaddress:billaddress,
            pincode:pincode,
            email:email,
            mobile:mobile,
            tel:tel,
            Products:Products
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },


    customerTransfer(userName, userPassword, command, customernum,lcocode,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: command,
            customernum:customernum,
            lcocode:lcocode
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/stock/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    casList(userName, userPassword,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "caslist",
            lcocode:localStorage.getItem("currentlco"),
            // lcocode:"JAK98616237"
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    getPackList(userName, userPassword,cas,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "productlist",
            cas: cas,
            lcocode:localStorage.getItem("currentlco"),
            // lcocode:"JAK98616237"
           
           
           
        });

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", url);
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },


    extendAPI(userName, userPassword,customerno,extend,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "extend",
            customerno:customerno,
            extend:extend,
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        
        
        var URL=domainName+"/api/customerno/";
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    getExtendList(userName, userPassword,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "expirylist",
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        var URL=domainName+"/api/mobilelogin/";
        
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    suspendAPI(userName, userPassword,customerno,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "suspend",
            customerno:customerno,
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        var URL=domainName+"/api/customerno/";
        
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    resumeAPI(userName, userPassword,customerno,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "resume",
            customerno:customerno,
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        var URL=domainName+"/api/customerno/";
        
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    bulkResumeAPI(userName, userPassword,customerno,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "bulkresume",
            customerno:customerno,
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        var URL=domainName+"/api/customerno/";
       
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },

    BulkSuspendAPI(userName, userPassword,customerno,domainName) {

      
        const body = JSON.stringify({
            username: userName,
            password: userPassword,
            command: "bulksuspend",
            customerno:customerno,
            lcocode:localStorage.getItem("currentlco")
            // lcocode:"JAK98616237"
           
        });
        

        console.log("POST BODY", body);
        var URL=domainName+"/api/customerno/";
        
        return fetch(URL, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getView360(userName, userPassword,stb,domainName) {

      
        const body = JSON.stringify({
            username: userName, 
            password: userPassword, 
            command: "stbfullview",
            lcocode: localStorage.getItem("currentlco"),
            stb: stb
          });
        

        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    




    getAdvt(userName, password, domainName) {
        const body = JSON.stringify({
            username: userName,
            password: password,
            command: "advt",
            lcocode: localStorage.getItem("currentlco"),


            // lcocode:"JAK98616237"

        });


        console.log("POST BODY", body);
        const url = domainName + "/api/mobilelogin/";
        console.log("URL", mobileUrl);
        return fetch(mobileUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    sendOTP(userName, password, number, OTP) {
        const body = JSON.stringify({
            username: userName,
            password: password,
            lcocode: localStorage.getItem("currentlco"),
            command: "sendsms",
            number: number,
            message: `OTP ${OTP}`


            // lcocode:"JAK98616237"

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(mobileUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getNoficationForPop(userName, password) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "notify",
            lcocode: localStorage.getItem("currentlco")

        });


        // console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(mobileUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    addPackageAddScheduler(userName, password, stb, packageID, date) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "schedule_create",
            "schedule_command": "add",
            "stb": stb,
            "packageid": packageID,
            "rundate": date,
            "lcocode": localStorage.getItem("currentlco")

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(customerUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getSchedulerList(userName, password, stb) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "schedule_list",
            "stb": stb,
            "lcocode": localStorage.getItem("currentlco")

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(customerUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    DisenbleSchedule(userName, password, stb, id) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "schedule_disable",
            "stb": stb,
            "id": id,
            "lcocode": localStorage.getItem("currentlco")

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(customerUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    EnbleSchedule(userName, password, stb, id) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "schedule_enable",
            "stb": stb,
            "id": id,
            "lcocode": localStorage.getItem("currentlco")

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(customerUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    addPackageRemoveScheduler(userName, password, stb, packageID, date) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "schedule_create",
            "schedule_command": "remove",
            "stb": stb,
            "packageid": packageID,
            "rundate": date,
            "lcocode": localStorage.getItem("currentlco")

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(customerUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    },
    getSTBList(userName, password, status) {
        const body = JSON.stringify({
            "username": userName,
            "password": password,
            "command": "allstb",
            "lcocode": localStorage.getItem("currentlco"),
            "status": status

        });


        console.log("POST BODY", body);

        // console.log("URL", mobileUrl);
        return fetch(mobileUrl, {
            method: 'POST',
            headers: {
                'Authorization': AuthID,
                'Content-Type': 'application/json'
            },
            body
        });
    }








}
export default API;