import React from 'react';
import Drawer from 'react-drag-drawer';
import '../../screens/CustomerDetails/index.css';
import {
    FaSearch
} from 'react-icons/fa';
import {
    MdClose,MdRemoveCircle
} from 'react-icons/md';
import Undraw from 'react-undraw';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus, BtnGroupRight, STBGroup, STBDetails,
    CustomerStatusDiv, ActivPackage, ChannelBox, ChannelName, ChannelPrice,AddPackageHeading,SelectedPackage,SelectedDiv,
    BalanceDiv,CurrentBal,TotalBal,AvailableBal

} from './styled';
import { FormControl, FormGroup, FormLabel, Container, ControlLabel, HelpBlock, Checkbox, Radio, Button,InputGroup,Form,Card,Col,Row  } from 'react-bootstrap';
 export default class AddPack extends React.Component{
     constructor(props){
         super(props);
         this.state={
            searchText:"",
            isShowWarning:false
         }
     }
    toggle = () => {
        let { toggle } = this.state
      
        this.setState({ toggle: !toggle })
      }
      isShowProDataVal(total){
          if(this.props.isShowProData){
            return(
                <label>
                    <TotalBal>Return Amount : <label>{'\u20B9'} {this.props.selectedPackPriceRemove}</label></TotalBal><AvailableBal>    Return + current Balance : <label>{'\u20B9'} {total}</label></AvailableBal>
                   </label>            
            );
          }else{
              return(
                  <label style={{color:"red",fontWeight:"bold",marginRight:500}}>Amount is not refund</label> 
              );          
                }
      }
     render(){
        if(this.props.isShowRemovePackageDialogBox){
            //console.log("removePackageSearchResultList",this.props.removePackageSearchResultList);
            var total= (parseFloat(this.props.lcoBalance) + parseFloat(this.props.selectedPackPriceRemove)).toFixed(2);
            // console.log("total",total);
         return(
             
        <div>
              
            <Drawer
                
                open={this.props.isShowRemovePackageDialogBox}
                onRequestClose={this.toggle}
                direction='left' className="popup-width"
            >
                {/* <div>Hey Im inside a drawer!</div> */}
               
                        
                      <div style={{overflow:"auto",background:"#ffffff",display:"table"}}>
                      <label style={{fontSize:15,fontWeight:"bold",color:"red"}}>{this.props.isEmptyErrorInRemovePackDialog}</label> 
               <AddPackageHeading><MdRemoveCircle  className="popup-icon" /> Remove Packages</AddPackageHeading>
               <BalanceDiv className="bal-div"><CurrentBal>Current Balance : <label>{'\u20B9'}  {this.props.lcoBalance}</label></CurrentBal>{this.isShowProDataVal(total)}  </BalanceDiv>
               <strong className="selected-nm">Selected Packages</strong>
               <SelectedDiv className="selected-div">
               {this.props.removePackageSelected.map((data,i)=>{
                return(
                   <SelectedPackage className="mr-3">{data.name} | {'\u20B9'} {data.price} <label>{data.id}</label> <MdClose onClick={()=>{this.props.removePackDialog(data.id)}}/></SelectedPackage>
                   )}
                   )}
                   </SelectedDiv>
               <SearchDiv style={{float:"none",width:"97%",marginLeft:11,marginRight:11}} className="mt-2 mb-2">
                        <input
                        value={this.state.searchText}
                            type="text" className="textBox" placeholder="Search..." style={{ lineHeight: 2.3, background: "#bfec99", border: 0, width: "100%", borderRadius: 35 }}
                            //    onPaste={this.renderTheSearchResult()}
                            //    onKeyPress={this.renderTheSearchResult()}
                            onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                            onKeyPress={(e) => {

                                if (e.keyCode === 13 || e.which === 13) {
                                    this.props.onRemoveSearchPackage(this.state.searchText)
                                }
                            }}
                        />
                        <FaSearch className="search-icons"></FaSearch>
                        </SearchDiv>
               <div style={{height:300,overflow:"auto",borderTop:"1px solid #f1f1f1",padding:21}}>
               <Row>
               {this.props.removePackageSearchResultList.map((data,i)=>{
                return(
                    <Col lg={3} md={4} sm={6} xs={12} className="mb-3 pr-0">
                    <Card className="package-details" key={i} style={{boxShadow:"1px 1px 3px 0px #fbfbfb",border:"1px solid rgba(134, 134, 134, 0.125)"}}>

                  

<Card.Body>
                     <div style={{fontSize:17,color:"#132275",fontWeight:600}}>{data.name}</div>
                     <div style={{display:"inline",color:"#d61818",float:"left",fontWeight:600,fontSize:17}}>{'\u20B9'} {data.baseprice}</div>
                     <Form.Group controlId="formBasicChecbox" style={{float:"right",marginBottom:0}}>
    <Form.Check 
    onClick={()=>this.props.toggleChangeInRemovePackDialog(i,data.productid,data.name,data.baseprice,data.toggleMode)}
    type="checkbox" 
    label={data.toggleMode}  
    checked={data.toggleMode}/>
  </Form.Group> 
  
  
  
                     </Card.Body>
           
</Card>
</Col>

                
             )
                })
            }
     </Row>
           
            </div>
            <div className="footer-div">
            <Button onClick={()=>{this.setState({isShowWarning:true});}} variant="success"  className="pull-right mt-1 mr-3 ml-3">Remove</Button >
            <Button 
            onClick={()=>{this.props.showRemovePackageSchedulerDialog()}} 
            variant="success"  className="pull-right mt-1 mr-3 ml-3">Schedule</Button >
            <Button  variant="danger" className="pull-left mt-1 mr-3 ml-3"  onClick={() => { this.props.inVisibleRemovePackDialog()}}> Cancel</Button>     
            </div>
                            </div>       
                
            </Drawer>

            
            <Drawer

open={this.state.isShowWarning}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold" }}>Remove</div>
        <div style={{ fontSize: 18, }}>Do you want <span style={{ fontWeight: "bold", color: "red" }}>Remove </span>?</div>
        <div style={{ textAlign: "end" }}>
            <Undraw
                style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
                name="warning" />
            <Button
                style={{ marginRight: 15, backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {
                   this.setState({isShowWarning:false});

                }}
            >Cancel</Button>
            <Button

                style={{ marginRight: 15, backgroundColor: "green" }}
                className="success" className="mt-3 pull-right"
                onClick={() => {
                    this.setState({isShowWarning:false});
                    this.props.removePackage()

                }}
            >OK</Button>
        </div>




    </Card.Body>
</Card>

</Drawer>
           

            </div>
         );
        }else{
            return null;
        }
     }
 }