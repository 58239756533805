import React from 'react';


import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import logo from '../../assets/logo-3.png';
import '../CreateCustomer/index.css';
import API from '../../Api';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import loadinggif from '../../assets/loding-gif.gif';
import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight, RootLeft, RootValue, FooterUl, FooterLi } from './styled'
import {
  MdPowerSettingsNew,
  MdArrowBack
} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import Undraw from "react-undraw";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      lcoBalance: "",
      lcoName: "",
      lcocode: "",
      STBNo: props.specifyUnmappedSTB,
      customerNM: "",
      installAddress: "",
      billingaddress: "",
      pinCode: "",
      eMail: "",
      Mobile: "",
      Tell: "",
      isChecked: false,
      customerData: {},
      errorMessage: "",
      isShowLogOutDialog: false,
      aadharNo:"0",
      image:""

    };
    console.log("STB 1", this.props.specifyUnmappedSTB);
    // this.getDashboardDetails();
    this.getLCO_Details();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {




    console.log("target value", event.target.value)
    debugger;
    if (/\D/g.test(event.target.value)) {
      console.log("target value", event.target.value)
      event.target.value = event.target.value.replace(/\D/g, '');



    }

    this.setState({ Mobile: event.target.value });

    //this.setState({value: event.target.value});
  }
  addressCheckBox(toggle) {
    debugger

    if (!toggle == true) {
      this.setState({ isChecked: true, installAddress: this.state.billingaddress });
    }
    else {
      this.setState({ isChecked: false, installAddress: "" });
    }
    //this.state.billingaddress : this.state.installAddress
  }
  onSubmit() {

    // debuggerif()
    const { lcoID, lcoPassword, STBNo, customerNM, installAddress, billingaddress, pinCode, eMail, Mobile, Tell, domainName,image,aadharNo } = this.state;
    debugger;
    if (installAddress && billingaddress && pinCode && Mobile) {
      this.setState({ spinnerVisible: true, errorMessage: "" });
   
      API.createCustomer(lcoID, lcoPassword, 'createcustomer', STBNo, customerNM, installAddress, billingaddress, pinCode, eMail, Mobile, Tell, domainName,image,aadharNo)
        .then((response) => response.json())
        .then((responsejson) => {
          debugger;

          // this.setState({ customerData: responsejson })

          // console.log("createCustomer", this.state.customerData);
          this.setState({ spinnerVisible: false });
          console.log("response", responsejson);
          debugger;
          this.props.moveToAddPackPage(responsejson.Customernum, this.state.STBNo);

        }).catch(e => {
          console.log("error", e);
          this.setState({ errorMessage: "Unable to process", spinnerVisible: false });
          debugger;

        });
    }
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />
    } else {
      return null;
    }
  }
  logOut() {

    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOUnmappedSTB() {
    this.props.moveToBackToUnmappedSTB();
  }

  getLCO_Details() {
    this.setState({ isSpinnerVisible: true })
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        this.setState({ lcoName: responsejson.lconame, lcoBalance: responsejson.balance, lcocode: responsejson.lcocode, isSpinnerVisible: false });

      });
  }

  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay

          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

        ></LoadingOverlay>

        <Drawer

          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: 'bold', }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success" className="mt-3 pull-right"
                onClick={() => {


                  this.setState({ isShowLogOutDialog: false })

                }}
              >Cancel</Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {

                  // this.props.hindNodataDialog();
                  this.logOut();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>


        <div className="header-div">
          <MdArrowBack onClick={() => this.backTOUnmappedSTB()} className="backTo" />
          <h3 className="" style={{ marginLeft: '98px' }}>Create Customer</h3>
          <img src={logo} style={{ float: "left", marginLeft: " -180px" }} className="logo-img" alt="logo" />

          <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />

        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>
              Name :
            </RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => { this.props.onClickProfile() }} >
              {this.state.lcoName}

            </RootValue>
            <div className="lcocode" >

              (<span style={{ color: "black" }}>Lco code:</span> {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>
              Balance :
            </RootTitle>

            <RootValue>
              {'\u20B9'} {this.state.lcoBalance}
            </RootValue>

          </RootRight>
        </RootDiv>
        <div className="mt-3">
          {this.state.errorMessage && <label style={{color:"red"}}>{this.state.errorMessage}</label>}
          <div></div>

          <Form className="form-div">
            <h3>Create Customer</h3>
            <Row>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Name <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" required onChange={(e) => { this.setState({ customerNM: e.target.value }) }}

                    value={this.state.customerNM} />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Billing Address <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Billing Address" required onChange={(e) => { this.setState({ billingaddress: e.target.value }) }}

                    value={this.state.billingaddress} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} className="mt-5">
                <Form.Group controlId="formBasicChecbox">
                  <Form.Check type="checkbox" label="Installation address same as Billing Address" checked={this.state.isChecked} onClick={(e) => this.addressCheckBox(this.state.isChecked)} />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Installation Address <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Installation Address" required onChange={(e) => { this.setState({ installAddress: e.target.value }) }}

                    value={this.state.isChecked ? this.state.billingaddress : this.state.installAddress} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Pincode <span>*</span></Form.Label>
                  <Form.Control type="number" placeholder="Enter Pincode" required onChange={(e) => { this.setState({ pinCode: e.target.value }) }}

                    value={this.state.pinCode} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email ID" onChange={(e) => { this.setState({ eMail: e.target.value }) }}

                    value={this.state.eMail} />

                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Mobile No <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Mobile No" maxLength="10" minLength="10" required onChange={this.handleChange}

                    value={this.state.Mobile} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Telephone No</Form.Label>
                  <Form.Label> (044+) </Form.Label><Form.Control type="number" placeholder="Enter Telephone No" onChange={e => { this.setState({ Tell: e.target.value }) }}

                    value={this.state.Tell} />
                </Form.Group>

              </Col>
            </Row>
            <Button
              onClick={() => this.onSubmit()}
              variant="success"
            >
              Submit
            </Button>
          </Form>
        </div>


        {this.logOutFunc()}
      </Rootcontainer>


    )
  }
}