import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import MTGif from '../../assets/MTGif.gif';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {

    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowNodataDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, }}>Do you want <span style={{ color: "red", fontWeight: "bold" }}>create new customer</span> ?</div>


                            <Button
                                style={{ backgroundColor: "red", borderColor: "red" }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindNodataDialog();

                                }}
                            >cancel</Button>
                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.moveTOUnmappedStbCreationFormScreen();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowRechargeWarningDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "rgb(26, 172, 195)", fontSize: 18, fontWeight: "bold", width: 300 }}>Recharge </div>
                            <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Recharge your balance</span> ?</div>
                            <Undraw
                                style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
                                name="credit-card-payments" />
                            <Button
                                style={{ backgroundColor: "red", borderColor: "red", marginLeft: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindRechargeWarningDialog();


                                }}
                            >Cancel</Button>
                            <a
                                style={{ marginLeft: 173, marginTop: 15 }}
                                className="btn btn-success"
                                role="button"
                                onClick={() => { this.props.hindRechargeWarningDialog() }}
                                href={`https://sms.bluechipsystems.net/billing/Agree_Payment_Request/?lcocode=${this.props.lcocode}&user=${this.props.lcoID}`}
                                target="_blank"
                            >ok</a>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowMantainanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            
                            <label style={{fontSize:20,fontWeight:"bold",textAlign:"center",marginLeft:20}}>Maintenance Operation Going on ! </label>
                            <div style={{width:350,height:250,marginBottom:30}}>
                            <img 
                            style={{width:350,height:250}}
                            src={MTGif} className="spinnerContent" color="green" />
                           </div>

                           <Button
                                style={{ backgroundColor: "#E64A19", borderColor: "#E64A19",marginRight:130,marginTop:40 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.getDashboardDetails();


                                }}
                            >Refresh</Button>



                        </Card.Body>
                    </Card>

                </Drawer>






            </div>
        );
    }
}