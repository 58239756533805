import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';

export default class DialogBoxs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: localStorage.getItem("userPassword"),
            enterCorrectPassword: false,
            enteredPassword: "",
            isMissMatchPassword: false,
            newPassword: "",
            confirmNewPassword: "",
            isShowNewPasswordDialog: false
        }
    }
    isCorrectPassword() {
        console.log("data enter check enteredPassword",this.state.enteredPassword);
        console.log("data oldPassword",localStorage.getItem("userPassword"));
        if (this.state.enteredPassword == localStorage.getItem("userPassword")) {
            this.setState({ enterCorrectPassword: false, isShowNewPasswordDialog: true, enteredPassword: "" });
            this.props.hindChangePasswordDialog();
        } else {
            this.setState({ enterCorrectPassword: true });
        }
    }

    isShowWrongPassword() {
        if (this.state.enterCorrectPassword) {
            return (
                <div style={{ color: "red", fontSize: 15, textAlign: "center", fontWeight: "bold", width: 300 }}>Wrong password !</div>
            );
        } else {
            return null
        }
    }
    checkNewAndConfirmSame() {
        if (this.state.newPassword == this.state.confirmNewPassword) {
            this.setState({ isShowNewPasswordDialog: false, isMissMatchPassword: false, newPassword: "", confirmNewPassword: "" });
            this.props.setNewPassword(this.state.newPassword);
        } else {
            this.setState({ isMissMatchPassword: true });
        }
    }

    isShowMissMatchError() {
        if (this.state.isMissMatchPassword) {
            return (
                <div style={{ color: "red", fontSize: 15, textAlign: "center", fontWeight: "bold", width: 300 }}>Miss match!</div>
            );
        } else {
            return null
        }
    }
    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowRechargeWarningDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 ">

                            <div style={{ color: "rgb(26, 172, 195)", fontSize: 18, fontWeight: "bold", width: 300 }}>Recharge </div>
                            <div style={{ marginLeft: "30px", marginTop: 10 }}> Do you want <span style={{ fontWeight: "bold", color: "red" }}> Recharge your balance</span> ?</div>
                            <Undraw
                                style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150, marginLeft: "-10px" }}
                                name="credit-card-payments" />
                            <Button
                                style={{ backgroundColor: "red", borderColor: "red", marginLeft: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.props.hindRechargeWarningDialog();
                                    

                                }}
                            >Cancel</Button>
                           <a
                           style={{marginLeft:173,marginTop:15}}
                           className="btn btn-success"
                           role="button"
                           onClick={()=>{this.props.hindRechargeWarningDialog()}}
                           href={`${this.props.paygateway}?lcocode=${this.props.lcocode}&user=${this.props.lcoID}`}
                           target="_blank"
                           >ok</a>



                        </Card.Body>
                    </Card>

                </Drawer>


                


            </div>
        );
    }
}