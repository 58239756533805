import React from "react";

import { Redirect } from "react-router-dom";
import loadinggif from "../../assets/loding-gif.gif";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Card,
  Modal
} from "react-bootstrap";
import logo from "../../assets/logo-3.png";
import "../UnmappedSTB/index.css";
import API from "../../Api";

import { MdPowerSettingsNew ,MdArrowBack} from "react-icons/md";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import {
  Rootcontainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  RootLeft,
  RootValue,
  FooterUl,
  FooterLi
} from "./styled";
import { MdAdd } from "react-icons/md";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      unmappedSTB: [],
      errorMessage: "",
      spinnerVisible: false,
      stbName: "",
      stbNumber: "",
      isShowLogOutDialog: false
    };
    console.log("username", localStorage.getItem("userID"));
    // this.getDashboardDetails();
    this.getLCO_Details();
  }
  getUnMappedSTB(e) {
    this.setState({ spinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getUnMappedSTB(lcoID, lcoPassword, "unmappedstb", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({ unmappedSTB: responsejson, spinnerVisible: false });

        console.log("unmappedstb", this.state.unmappedSTB);
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  getLCO_Details() {
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode,
          isSpinnerVisible: false
        });
      });
  }

  renderUnmappedSTB() {
    if (this.state.unmappedSTB) {
      return (
        <Row style={{ paddingLeft: 27, margin: "auto" }} className="mt-1">
          {this.state.unmappedSTB.map((data, i) => {
            return (
              <Col sm={6} lg={3} md={4} xs={12} className="mt-3 pl-0">
                <Card className="card-shadow">
                  <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                    <Card.Title>{data.fields.SNo}</Card.Title>
                    <Card.Subtitle className="mb-1 mt-1 text-muted">
                      Model :{" "}
                      <label style={{ color: "red" }}>
                        {data.fields.Model}
                      </label>
                    </Card.Subtitle>
                    <i
                      className="stbicons"
                      onClick={() => this.AddCustomer(data.fields.SNo)}
                    >
                      <MdAdd />
                      Add
                    </i>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    }
  }
  AddCustomer(item) {
    this.props.moveToCreateCustomerForm(item);
  }

  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay
          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={
            <img
              src={loadinggif}
              className="spinnerContent"
              color="green"
              style={{ marginLeft: 100 }}
            />
          }
        ></LoadingOverlay>

        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
        {/* <div className="header-div">
                    <h3>STB Mapping</h3>
                    <img src={logo} className="logo-img" />
                    <MdPowerSettingsNew onClick={()=>{this.setState({isShowLogOutDialog:true})}} value="Logout" className="fontSize"/>
                </div> */}
        <div className="header-div">
        <MdArrowBack onClick={() => this.props.onMoveToTransactions()} className="back-button" style={{ marginRight: 15 }} />
          <h3 className="" style={{ marginLeft: "98px" }}>
            STB Mapping
          </h3>
          <img
            src={logo}
            style={{ float: "left", marginLeft: " -150px" }}
            className="logo-img"
            alt="logo"
          />

          <MdPowerSettingsNew
            onClick={() => {
              this.setState({ isShowLogOutDialog: true });
            }}
            value="Logout"
            className="fontSize login"
          />
        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer", marginRight: "150px " }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}{" "}
              <div className="lcocode">({this.state.lcocode})</div>
            </RootValue>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>

        <Button
          variant="info"
          size="sm"
          className="ml-4 mt-1"
          onClick={e => {
            this.getUnMappedSTB(e);
          }}
        >
          Show Unmapped STB
        </Button>

        {this.renderUnmappedSTB()}

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
