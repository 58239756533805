import React from "react";
import Drawer from "react-drag-drawer";
import "../../screens/CustomerDetails/index.css";

import { Card } from 'react-bootstrap';
import { MdTransferWithinAStation } from "react-icons/md";
import {
  AddPackageHeading,
} from "./styled";
import {
  Button,
} from "react-bootstrap";
import Checkboxs from 'muicss/lib/react/checkbox';
export default class Scheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      isShowDisableDialog:false,
      isShowEnableDialog:false,
      isShowPackageDialog:false,
      selectedPackageData:[]
    };
  }
  toggle = () => {
    let { toggle } = this.state;

    this.setState({ toggle: !toggle });
  };
  render() { 
  
      return (
          <div>
        <Drawer
          open={this.props.isShowSchedulerListDialog}
          onRequestClose={this.toggle}
          direction="bottom"
          className="popupwidth"
        >
          {/* <div>Hey Im inside a drawer!</div> */}

          <div
            style={{
              overflow: "auto",
              background: "#ffffff",
              display: "table",
              maxHeight:"90vh",
              width:"95vw"
            }}
          >
           

            <AddPackageHeading>
              <MdTransferWithinAStation className="popup-icon" />
              Scheduler Details
            </AddPackageHeading>
            {/* <div  style={{alignSelf:"end",alignItems:"end",justifyItems:"end"}} >
            <Button
                variant="success"
                className="pull-left mt-1 mr-3 ml-3"
                onClick={() => {
                 this.setState({isShowEnableDialog:true})
                }}
              >
             
                Enable
              </Button>
              <Button
                variant="danger"
                className="pull-left mt-1 mr-3 ml-3"
                onClick={() => {
                    this.setState({isShowEnableDialog:true})
                }}
              >
               
                Disable
              </Button>
            </div> */}

            <div style={{ borderTop: "1px solid #f1f1f1", padding: 21 }}>
            
              <table className="table table-striped">
                <thead>
                {/* <th style={{ width: "3%" }}></th> */}
                  <td>STB</td>
                  <td>user</td>
                  <td>Action</td>
                  <td>created</td>
                  <td>run Date</td>

                  <td>products</td>
                  <td>executed</td>
                  <td>disabled</td>
                  <td>Process</td>
              
                 
                </thead>
                <tbody>
                  {this.props.schedulerDataList.map((data, i) => {
                   
                    return (
                      <tr>
                        {/* <td style={{ color: "#ea3232" }}>{data.Products}</td> */}
                        {/* <td style={{ width: "3%" }}>


<Checkboxs
    style={{ fontSize: 20, color: GeneralValues.mustard}}
    // color="green"
    

    onChange={() => { this.props.toggleChangeInScheduler(i) }}
    checked={data.toggleMode} />
</td> */}
                        <td>{data.settop}</td>
                        <td>{data.user}</td>
                        <td style={{color:data.command == "add" ? "green":"red"}}>{data.command}</td>
                        <td>{data.created}</td>
                        <td>{data.rundate}</td>
                        <td style={{maxLines:2,maxHeight:47,overflow:"hidden",textOverflow:"ellipsis"}}>
                                            <div style={{height:47,overflow:"hidden"}}> {data.products.map((item)=>{
                                                return(
                                                <label style={{fontSize:11}}>{item}, </label>
                                                )
                                            })}</div>
                                            {data.products.length >2 ? <label onClick={()=>{
                                                this.setState({selectedPackageData:data.products,isShowPackageDialog:true})
                                            }} style={{color:"#e9b42a",textDecoration:"underline"}}>More</label>:null}
                                           
                                             </td>
                                        <td style={{color:data.executed  ? "green":"red"}}>{data.executed ? "YES":"NO"}</td>
                                        <td style={{color:data.executed ? "green":"red"}}>{data.disabled ? "YES":"NO"}</td>
                                        <td >
                                            {data.disabled ? 
                                            <label
                                            onClick={()=>{
                                               this.props.EnbleScheduler(data.id)
                                            }}
                                            style={{color:"green",textDecoration:"underline",cursor: "pointer"}}>Enable</label>
                                            : <label 
                                            onClick={()=>{
                                                this.props.disableScheduler(data.id);
                                            }}
                                            style={{color:"red",textDecoration:"underline",cursor: "pointer"}}>Diable</label>}
                                            </td>
                                            
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-div" style={{ marginTop: "-28px" }}>
              <Button
                variant="danger"
                className="pull-left mt-1 mr-3 ml-3"
                onClick={() => {
                  this.props.hindSchedulerListDialog();
                }}
              >
                {" "}
                Close
              </Button>
            </div>
          </div>
        </Drawer>


        <Drawer

open={this.state.isShowDisableDialog}
onRequestClose={this.toggle}
direction='bottom'

>
{/* <div>Hey Im inside a drawer!</div> */}
<Card className="confirm-popup">

    <Card.Body>
        <div style={{ fontSize: 20, fontWeight: 'bold' }}> Disable</div>

        <div className="mt-3"> <strong>Are Your Sure Want to disable ?</strong> </div>

        <Button variant="danger" className="mt-3 pull-right mr-2 ml-2"
            onClick={() => {
                this.setState({ isShowDisableDialog: false })
            }}
        >Cancel</Button>
        <Button variant="success" className="mt-3 pull-right mr-2 ml-2"
            onClick={() => {
                this.confirmToDeactivate()
            }}
        >OK</Button>
    </Card.Body>
</Card>

</Drawer>
<Drawer

open={this.state.isShowDisableDialog}
onRequestClose={this.toggle}
direction='bottom'

>
{/* <div>Hey Im inside a drawer!</div> */}
<Card className="confirm-popup">

    <Card.Body>
        <div style={{ fontSize: 20, fontWeight: 'bold' }}> Enable</div>

        <div className="mt-3"> <strong>Are Your Sure Want to enable ?</strong> </div>

        <Button variant="danger" className="mt-3 pull-right mr-2 ml-2"
            onClick={() => {
                this.setState({ isShowEnableDialog: false })
            }}
        >Cancel</Button>
        <Button variant="success" className="mt-3 pull-right mr-2 ml-2"
            onClick={() => {
                this.confirmToDeactivate()
            }}
        >OK</Button>
    </Card.Body>
</Card>

</Drawer>


<Drawer

                    open={this.state.isShowPackageDialog}
                    onRequestClose={this.toggle}
                    direction='bottom'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Packages:</div>

                            <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: "rgb(26, 172, 195)", overflow: "auto", marginTop: 20 }}>
                                {this.state.selectedPackageData ?
                                this.state.selectedPackageData.map((item, i)=>{
                                    return(
                                    <label style={{fontSize:13,marginRight:10}}>{`${item},`}</label>
                                    );
                                }):null
                            }
                            </div>


                            <Button
                                style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({isShowPackageDialog:false});

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>


                </Drawer>
        </div>
      );
   
  }
}
