import React from 'react';
import {
    Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card, Form, Row, Col,
    Dropdown, DropdownButton, Modal
} from 'react-bootstrap';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, SelectedDiv, SelectedPackage, FooterUl, FooterLi
} from './styled';
import { Redirect } from 'react-router-dom';
import logo from '../../../assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import Checkboxs from 'muicss/lib/react/checkbox';
import { CSVLink, CSVDownload } from "react-csv";
import Loader from 'react-loader-spinner';
import {
    MdPowerSettingsNew,
    MdArrowBack,
    MdClose
} from 'react-icons/md';
import './index.css';
// import DialogBoxs from './DialogBoxs';
import API from '../../../Api';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
// import Date from './Date';
import CSVReader from "react-csv-reader";

import loadinggif from '../../../assets/loding-gif.gif';
import DialogBoxes from './DialogBoxes';
// import date from 'date-and-time';
// import {samples} from './CSVSample';



export default class CreateCustomerWithSTB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloggedOut: false,
            isShowLogOutWarning: false,
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            lcoList: JSON.parse(localStorage.getItem("lcolist")),
            isSpinnerVisible: false,
            dataList:[],
            CSVError:"",
            selectedDataList:[],
            isShowProcessDialog:false,
            noOfDataGoingToProcess:0,
            noOfcompletedData:0,
            unProcessedData:[],
            isShowUnProcessedDataListDialog:false,
            isShowCreateWarning:false,
            noOfDataGoingToProcessforRef:0,
            isEmptyList:false
         


        }

        let arr = [];
        for (var i = 0; i < this.state.lcoList.length; i++) {
            arr.push({ "id": this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
        }
        console.log("data in kk", arr);
        this.state.modData = arr;
        this.state.lcoSearchResult = arr;
        this.setState({ modData: arr, lcoSearchResult: arr });
    }
  
  
   

   

    
   

    hindLogOutDialog() {
        this.setState({ isShowLogOutWarning: false });
    }
    hindEmptyList(){
        this.setState({isEmptyList:false})
    }
    hindProcessDialog() {
        this.setState({ isShowProcessDialog: false, selectedDataList: [],  dataList: [] });
        if (this.state.unProcessedData.length) {
            this.setState({ isShowUnProcessedDataListDialog: true });
        } 
    }

    hindUnProcessedDataListDialog() {
        this.setState({ isShowUnProcessedDataListDialog: false });
       
    }
    hindCreateWarning(){
        this.setState({isShowCreateWarning:false})
    }

    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }

    handleCSVFileUpload = e => {
        var arrayOfData = [];
        console.log("csv", e);
        var len = e.length;
        for (var i = 1; i < len; i++) {
            if (e[i][0]) {
                var arr = e[i];
                var csvData = {
                    Name: arr[0],
                    LcoCode: arr[1],
                    toggleMode: true,
                    Address:arr[2],
                    City:arr[3],
                    State:arr[4],
                    Mandal:arr[5],
                    District:arr[6],
                    Pincode:arr[7],
                    caf:arr[8],
                    Mobile:arr[9],
                    Email:arr[10],
                    Tax:arr[11],
                    Lcocustid:arr[12],
                    Country:arr[13],
                    Telno:arr[14],
                    STBNo:arr[15],
                    MACVCChipNumber:arr[16],
                    Model:arr[17],
                    Status:arr[18],
                    Deactivation_Date:arr[19],
                    InstallDate:arr[20],
                    InstallRate:arr[21],
                    Supplier:arr[22],
                    Billingmodel:arr[23],
                    Product:arr[24],
                    cas:arr[25],
                    type:arr[26],
                    IDType:arr[27]





                    
                }
                arrayOfData.push(csvData);
            } else {
                console.log("data not found", e[i]);
            }
        }
        console.log("Array of data", arrayOfData, arrayOfData.length);
        if (!arrayOfData.length) {
            debugger;
            this.setState({ CSVError: "No data In CSV file", dataList: [] });
        } else {
            debugger;
            this.setState({ CSVError: "", dataList: arrayOfData });
        }

    }
   

    recursiveFuncCSV(data) {
        console.log("data", data);
        debugger;
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName} = this.state;
            API.createSTBWithCustomerAndProduct(
                lcoID, 
                lcoPassword, 
                "createcustomerstb",
                domainName,
                data.Name,
                data.LcoCode,
                data.Address,
                data.City,
                data.State,
                data.Mandal,
                data.District,
                data.Pincode,
                data.caf,
                data.Mobile,
                data.Email,
                data.Tax,
                data.Lcocustid,
                data.Country,
                data.Telno,
                data.STBNo,
                data.MACVCChipNumber,
                data.Model,
                data.Status,
                data.InstallDate,
                data.InstallRate,
                data.Supplier,
                data.Product,
                data.cas,
                data.type,
                data.Billingmodel,
                data.IDType  )
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) <= total) {
                            debugger;
                            this.recursiveFuncCSV(this.state.selectedDataList[n + 1])
                        }
                    } else {
                        debugger;
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error,STBNum:data.STBNo});
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncCSV(this.state.selectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }

    CreateFuncCSV() {
        var newCSVList = [];
        var previousCSVDataList = this.state.dataList;
        for (var i in previousCSVDataList) {
            if (previousCSVDataList[i].toggleMode) {
                newCSVList.push(previousCSVDataList[i]);
            }
        }
        if (newCSVList.length) {


            this.setState({
                
                noOfDataGoingToProcess: newCSVList.length,
                noOfcompletedData: 0,
                isShowProcessDialog : true,
                selectedDataList: newCSVList
            });
            this.state.selectedDataList = newCSVList;
            this.state.noOfDataGoingToProcess = newCSVList.length;
            this.state.noOfcompletedData = 0;
            console.log("new ", this.state.selectedDataList);

            debugger;

            this.recursiveFuncCSV(this.state.selectedDataList[0]);

        }



    }
    



    
    
    

    csvData = [
        [
            "Name",
            "LcoCode", 
            "Address",
            "City",
            "State",
            "Mandal",
            "District",
            "Pincode",
            "caf",
            "Mobile",
            "Email",
            "Tax",
            "Lcocustid",
            "Country",
            "Telephone no",
            "STB number",
            "MACVCChipNumber",
            "Model",
            "Status",
            "Deactivation_Date (dd-mm-yyyy)",
            "InstallDate (dd-mm-yyyy)",
            "InstallRate",
            "Supplier",
            "Billingmodel",
            "Product",
            "cas",
            "type",
            "IDType"
           
        ],

    ];
    
    toggleChangeInDataList(i){
       var oldDataList = this.state.dataList;
       var oldToggleMode = oldDataList[i].toggleMode;
       oldDataList[i].toggleMode = !oldToggleMode;
       this.setState({dataList:oldDataList})
    }
  
    render() {
        return (
            <Rootcontainer
                // onScroll={(e) => this.handleScroll(e)}
            // ref={this.nav}
            //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
            >

                <LoadingOverlay

                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

                ></LoadingOverlay>


                <div className="header-div">
                    <MdArrowBack onClick={() => this.props.backToStockOperation()} className="back-button" style={{ marginRight: 15 }} />
                    <h3 className="" style={{ marginLeft: '98px' }}>Bulk customer and settop box Creation</h3>
                    <img src={logo} style={{ float: "left", marginLeft: " -398px" }} className="logo-img" alt="logo" />

                    <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutWarning: true }) }} value="Logout" className="fontSize login" />

                </div>

                <MainContainer>

                <table className="table table-striped">
                <div style={{textAlign:"end"}}>
                <Button
                onClick={()=>{
                    var newCSVList = [];
                    var previousCSVDataList = this.state.dataList;
                    for (var i in previousCSVDataList) {
                        if (previousCSVDataList[i].toggleMode) {
                            newCSVList.push(previousCSVDataList[i]);
                        }
                    }
                    if(newCSVList.length){

                    this.setState({isShowCreateWarning:true,noOfDataGoingToProcessforRef:newCSVList.length})
                    }else{
                        this.setState({isEmptyList:true})
                    }
                }}
                style={{backgroundColor:"green",borderColor:"green",marginRight:20}}
                >Create</Button>
                <CSVReader

                        style={{ border: "2px solid #ececec", padding: 5, }}
                        cssClass="react-csv-input"
                        onFileLoaded={e => { this.handleCSVFileUpload(e) }}
                    />
                    </div>
                   

                    <div><label style={{ marginLeft: "60%",marginTop:10 }}>No of List: <span style={{ color: "red", fontWeight: "bold" }}>{this.state.dataList.length}</span>  
                    <CSVLink 
                    filename={`Create_customerWithSTB(${this.state.lcoID}).csv`}
                    data={this.csvData} style={{ display: "inline-block",marginLeft:15 }}>Download sample file</CSVLink>    </label> </div>


                    <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>{this.state.isEmptyCSVList}</div>
                    <thead>
                        <th style={{ width: "3%" }}></th>
                        
                        <th>Name</th>
                        <th>Mobile no</th>
                        <th>Address</th>
                        <th>Lco code</th>
                        <th> STB Number </th>
                        <th> MACVCChipNumber</th>
                          



                    </thead>
                    <tbody className="total-records">
                        {this.state.dataList.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>


                                        <Checkboxs
                                            style={{ fontSize: 20, color: "green",
                                            width: "20px",
                                            height:" 20px "
                                        }}
                                            color="green"

                                            onChange={() => { this.toggleChangeInDataList(i) }}
                                            checked={data.toggleMode} />
                                    </td>



                                    <td>{data.Name}</td>
                                    <td>{data.Mobile}</td>
                                    <td>{data.Address}</td>
                                    <td>{data.LcoCode}</td>
                                    <td>{data.STBNo}</td>
                                    <td style={{  }}>{data.MACVCChipNumber}</td>
                    
                                  

                                </tr>
                            )
                        })}
                    </tbody>
                    </table>
               
                   



                </MainContainer>
                <DialogBoxes
                    isShowLogOutWarning={this.state.isShowLogOutWarning}
                    logOut={this.logOut.bind(this)}
                    hindLogOutDialog={this.hindLogOutDialog.bind(this)}
                    selectedDataList={this.state.selectedDataList}
                    noOfDataGoingToProcess={this.state.noOfDataGoingToProcess}
                    noOfcompletedData={this.state.noOfcompletedData}
                    unProcessedData={this.state.unProcessedData}
                    hindProcessDialog={this.hindProcessDialog.bind(this)}
                    CreateFuncCSV={this.CreateFuncCSV.bind(this)}
                    hindUnProcessedDataListDialog={this.hindUnProcessedDataListDialog.bind(this)}
                    isShowUnProcessedDataListDialog={this.state.isShowUnProcessedDataListDialog}
                    isShowCreateWarning={this.state.isShowCreateWarning}
                    hindCreateWarning={this.hindCreateWarning.bind(this)}
                    noOfDataGoingToProcessforRef={this.state.noOfDataGoingToProcessforRef}
                    isShowProcessDialog={this.state.isShowProcessDialog}
                    isEmptyList={this.state.isEmptyList}
                    hindEmptyList={this.hindEmptyList.bind(this)}
                   
                />


                {this.logOutFunc()}

            </Rootcontainer>

        );
    }
}
